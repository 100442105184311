import { Controller } from 'stimulus'
import $ from 'jquery'
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    path: String,
    type: String
  }

  connect() {
    if (this.typeValue === 'detail')
      this.getBalance()
  }

  getBalance() {
    $('.reload-btn').addClass('is-loading')
    Rails.ajax({
      url: this.pathValue,
      type: 'GET',
      dataType: 'json',
      success: function (response) {
        $('.reload-btn').removeClass('is-loading')
        $('.wallet-balance').html(response.wallet_balance ? response.wallet_balance : response)
        if (typeof response.platform_balance !== 'undefined')
          $('.platform-balance').html(response.platform_balance)

        if (typeof response.total_balance !== 'undefined')
          $('.total-balance').html(response.total_balance)
      },
      error: function (response) {
        $('.reload-btn').removeClass('is-loading')
        // TODO:check error
        // alert(response)
      }
    })
  }
}

