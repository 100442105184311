import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import $ from "jquery";

export default class extends Controller {
  static values = {
    path: String
  }
  static targets = ['options', 'currency', 'type', 'betAmountRequired']

  connect() {
    this.generateOrderTypes()
  }

  generateOptions(event) {
    if (!event || (event.currentTarget.id === 'order_order_type_id' || event.currentTarget.id === 'genre')) {
        $('.sub-options').hide()
      }

    let orderType = $('#order_order_type_id option:selected')
    let orderTypeName = orderType.attr('data-name')
    let orderTypeValue = orderType.val()
    let agent = $('#agent_id').val()
    let currency = this.currencyTarget.value
    let betAmountRequireTimes = $('#order_bet_amount_required_times')
    betAmountRequireTimes.prop('disabled', true)

    if (agent && orderTypeValue) {
      switch (orderTypeName) {
        case 'agent_deposit':
          if (!event || event.currentTarget.id !== 'currency') {
            this.generateUsers(agent, orderTypeValue)
            $(this.betAmountRequiredTarget).show()
          }
          betAmountRequireTimes.prop('disabled', false)
          break
        case 'third_party_withdraw':
          if (currency === 'CNY') {
            this.generateWithdrawAccounts(agent, orderTypeValue)
          } else {
            this.generateCurrency(agent, orderTypeValue)
          }
          break;
        case 'commission_withdraw':
          this.generateWithdrawAccounts(agent, orderTypeValue)
          break
        case 'adjust_withdraw':
          if (currency === 'USDT') {
            this.generateWithdrawAccounts(agent, orderTypeValue)
          } else {
            $('.sub-options').hide()
            this.generateCurrency(agent, orderTypeValue)
          }
          break;
        default:
          this.generateCurrency(agent, orderTypeValue)
          break
      }
    }
  }

  generateUsers(agent, order_type) {
    Rails.ajax({
      type: 'GET',
      url: this.pathValue + '?order_type=' + order_type + '&agent_id=' + agent,
      dataType: 'json',
      success: (response) => {
        this.generateDropdownListByHash(
            'user_id',
            response['list'],
            'id',
            'username'
        )
        this.generateDropdownListByArray('currency', response['currencies'], this.currencyTarget.value)
      },
      error: function (response) {
        alert(response);
      }
    })
  }

  generateWithdrawAccounts(agent, order_type) {
    let currency = this.currencyTarget.value
    $('.sub-options').hide()

    if (currency) {
      Rails.ajax({
        type: 'GET',
        url: this.pathValue + '?order_type=' + order_type + '&agent_id=' + agent + '&currency=' + currency,
        dataType: 'json',
        success: (response) => {
          this.generateDropdownListByHash(
              currency === 'USDT' ? 'blockchain_address_id' : 'bank_account_id',
              response['list'],
              'id',
              'mask'
          )
          this.generateDropdownListByArray('currency', response['currencies'], currency)
        },
        error: function (response) {
          alert(response);
        }
      })
    } else {
      this.generateCurrency(agent, order_type)
    }
  }

  generateCurrency(agent, order_type) {
    Rails.ajax({
      type: 'GET',
      url: this.pathValue + '?order_type=' + order_type + '&agent_id=' + agent,
      dataType: 'json',
      success: (response) => {
        this.generateDropdownListByArray('currency', response['currencies'], this.currencyTarget.value)
      },
      error: function (response) {
        alert(response);
      }
    })
  }

  generateOrderTypes() {
    let oriType = this.typeTarget.value
    let genre = $('#genre option:selected').val()

    Rails.ajax({
      type: 'GET',
      url: this.pathValue + '?genre=' + genre,
      dataType: 'json',
      success: (response) => {
        this.generateDropdownListByHash(
            'order_order_type_id',
            response['order_types'],
            'id',
            'human_description',
            oriType,
            {name: 'description'}
        )
        this.generateOptions()
      },
      error: function (response) {
        alert(response);
      }
    })
  }

  generateDropdownListByArray(target_id, data, selected = '') {
    let target = document.getElementById(target_id)
    let prompt = target.options[0]
    target.innerHTML = ''

    if (target_id === 'currency') {
      const promptOption = document.createElement("option")
      promptOption.value = prompt.value
      promptOption.text = prompt.text
      target.appendChild(promptOption)
    }

    $.each(data, function (item, value) {
      const option = document.createElement("option")
      option.value = value
      option.text = value
      target.appendChild(option)

      if (selected === value) {
        option.selected = true
      }
    })
    $(target).parent().show()
  }

  generateDropdownListByHash(target_id, data, value_field_name, text_field_name, selected = '', additional = {}) {
    let target = document.getElementById(target_id)
    target.innerHTML = ''

    $.each(data, function (item, value) {
      const option = document.createElement("option")
      option.value = value[value_field_name]
      option.text = value[text_field_name]
      $.each(additional, function (key, field) {
        option.setAttribute(`data-${key}`, value[field]);
      })
      target.appendChild(option)

      if (selected === String(value[value_field_name])) {
        option.selected = true
      }
    })
    $(target).parent().show()
  }
}
