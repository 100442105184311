import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    connect() {
        var topicUrl = window.location.href;
        var topicIndex = topicUrl.indexOf('#');
        var topicHash = topicUrl.substr(topicIndex,topicUrl.length);
        $(document).ready(function() {
            if (topicHash=='#1') {
                $(".topic-tab-input").attr("checked",false);
                $("#show-section1").attr("checked",true);
            } else if(topicHash=='#2') {
                $(".topic-tab-input").attr("checked",false);
                $("#show-section2").attr("checked",true);
            } else if(topicHash=='#3') {
                $(".topic-tab-input").attr("checked",false);
                $("#show-section3").attr("checked",true);
            } else if(topicHash=='#4') {
                $(".topic-tab-input").attr("checked",false);
                $("#show-section4").attr("checked",true);
            } else {
                $(".topic-tab-input").attr("checked",false);
                $("#show-section1").attr("checked",true);
            }
            $(".topic-tabs .tab").click(function() {
                var topicPageId = $(this).data("id");
                window.location.hash = topicPageId;
            })
        })
    }
}