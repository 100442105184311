import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import $ from "jquery";

export default class extends Controller {
  calculateFee(event) {
    let category = $(event.target).attr('data-category')
    let rate = $(event.target).val()
    let bet_result = $(`#${category}_result`).val()
    let fee = (bet_result * (rate / 100)).toFixed(2)
    $(`#${category}_fee`).html(this.number_with_delimiter(fee))
  }

  number_with_delimiter(number) {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}
