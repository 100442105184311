import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "selectProvince", "selectRegion" ]
  static values = {
    regions: String,
    selectedProvince: String,
    selectedRegion: String
  }

  connect() {
    this.generateProvinceOptions()
    if (this.selectedProvinceValue !== '') {
      this.selectProvinceTarget.value = this.selectedProvinceValue
      this.generateRegionOptions()
      this.selectRegionTarget.value = this.selectedRegionValue
    }
  }

  generateProvinceOptions() {
    let data = JSON.parse(this.regionsValue)
    let target = this.selectProvinceTarget

    $.each(data, function (province, regions) {
      const option = document.createElement("option")
      option.value = province
      option.text = province
      target.appendChild(option)
    })
  }

  generateRegionOptions() {
    let data = JSON.parse(this.regionsValue)
    let target = this.selectRegionTarget
    target.innerHTML = ''

    $.each(data[this.selectProvinceTarget.value], function (province, region) {
      const option = document.createElement("option")
      option.value = region['id']
      option.text = region['name']
      target.appendChild(option)
    })
  }
}

