import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = [ "name" ]

    clearWhitespace() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.nameTarget.value = this.nameTarget.value.trim();
        }, 400)
    }
}
