import {Controller} from 'stimulus'
import Rails from "@rails/ujs";

export default class extends Controller {
    static values = {
        path: String
    }

    connect() {
        this.getDepositInfo()
    }

    getSerialNumbers() {
        let serialNumbers = document.querySelectorAll(`.order-serialNumber`)
        return Array.from(serialNumbers).map(serialNumber => serialNumber.textContent.trim()).join(',');
    }

    setDepositInfo(response_orders) {
        for (let response_order of response_orders) {
            let order_detail = document.getElementById('order_deposit_information_' + response_order.serial_number)
            if (order_detail != null) {
                order_detail.innerText = response_order.third_party_payment_info.name
            }
        }
    }

    getDepositInfo() {
        Rails.ajax({
            type: 'GET',
            url: this.pathValue + '?send_for=deposit_order_info&serial_number=' + (this.getSerialNumbers() ?? ''),
            dataType: 'script',
            success: (response) => {
                if(response === false) return

                this.setDepositInfo(response)
            }
        });
    }
}
