import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "hostsDisplay", "promotionCode" ]
  static values = {
    promotionCodes: String
  }

  connect() {
    this.getHosts()
  }

  getHosts() {
    let data = JSON.parse(this.promotionCodesValue)
    let target = this.hostsDisplayTarget
    target.innerHTML = ''

    $.each(data[this.promotionCodeTarget.value], function (key, value) {
      const option = document.createElement("option")
      option.value = value.host_id
      option.text = value.host_name
      target.appendChild(option)
    })
  }
}

