import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
    static values = {
        path: String,
        language: String,
        preSelecting: Array,
        limit: Number
    }

    connect() {
        $(document).on('turbolinks:load', () => {
            this.enableSelect2()
        })
        this.enableSelect2()
    }

    enableSelect2(multiple = true) {
        let path = this.pathValue
        let language = this.languageValue
        let preSelecting = this.preSelectingValue

        $('#user_list ~ .select2-container').remove()

        $('#user_list').select2({
            ajax: {
                delay: 700,
                data: function(params) {
                    return {
                        user: { username: params.term },
                        page: params.page || 1
                    }
                },
                transport: function(params, success, _failure) {
                    Rails.ajax({
                        url: path,
                        type: 'GET',
                        dataType: 'json',
                        data: decodeURIComponent($.param(params.data)),
                        success: data => success(data)
                    })
                },
                processResults: function(data, params) {
                    params.page = params.page || 1
                    return {
                        results: $.map(data.user, function(user_data) { return { id: user_data.id, text: user_data.username } }),
                        pagination: {
                            more: (params.page * 50) < data.count
                        }
                    }
                }
            },
            cache: true,
            tags: true,
            tokenSeparators: [',', ' '],
            createTag: function(params) {
              return {
                id: `multiInput-${params.term}`,
                text: params.term,
                newTag: true
              }
            },
            multiple: multiple,
            language: language,
            width: '100%',
            maximumSelectionLength: this.limitValue ? this.limitValue : 0
        })

        if (preSelecting.length > 0) {
            $('#user_list').val(preSelecting)
            $('#user_list').trigger('change')
        }
    }
}