import { Controller } from 'stimulus'

export default class extends Controller {
    static values = { brandName: String }
    addBookmark() {
        const ua = navigator.userAgent.toLowerCase()
        const url = location.href
        const title = this.brandNameValue == 'h6' ? '黄金城 - 信誉就是最好的用户体验' : '福鹿会 - 给您最实在的优惠'
        if (ua.indexOf('360se') > -1) {
            alert('由于360浏览器功能限制，请按 Ctrl + D 手动收藏！')
        } else {
            try {
                window.external.addFavorite(url, title)
            } catch (e) {
                try {
                    window.sidebar.addPanel(title, url, '')
                } catch (e) {
                    alert('\n\t对不起，您的浏览器不支持此操作！\n\n\t请您使用菜单栏或 Ctrl + D 收藏本站。')
                }
            }
        }
        return false
    }
}