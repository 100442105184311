import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
  }

  toggleAll(e) {
    let allCheckBoxes = document.querySelectorAll("input[name='config_ids[]']");

    for (let checkBox of allCheckBoxes) {
      checkBox.checked = e.target.checked;
    }
  }

  toggleSub(e) {
    let methodName = e.target.parentElement.lastElementChild.textContent;
    let subCheckBoxes = document.querySelectorAll(`input[id=${methodName}]`);

    for (let checkBox of subCheckBoxes) {
      checkBox.checked = e.target.checked;
    }
  }
}
