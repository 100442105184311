import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'depositCheckBox', 'withdrawCheckBox', 'bonusCheckBox',
                     'rakebackCheckBox', 'transferCheckBox', 'adjustCheckBox' ]

  connect() {
    let names = ['deposit', 'withdraw', 'bonus', 'rakeback', 'transfer', 'adjust'];
    for(name of names) {
      let orderTypes = this.getOrderTypes(name);
      this[`${name}CheckBoxTarget`].checked = this.childCheckBoxesCheckedValue(orderTypes);
    }
  }

  toggleGroup(e) {
    let orderTypes = this.getOrderTypes(e.target.id);
    orderTypes.forEach(orderType => orderType.checked = e.target.checked );
  }

  toggleName(e) {
    let targetClass = e.target.parentElement.classList[2]
    let name = targetClass.split('-')[0]
    let orderTypes = this.getOrderTypes(name);
    this[`${name}CheckBoxTarget`].checked = this.childCheckBoxesCheckedValue(orderTypes);
  }

  childCheckBoxesCheckedValue(orderTypes) {
    for(let orderType of orderTypes) {
      if (!orderType.checked) return false;
    }
    return true;
  }

  getOrderTypes(name) {
    return document.querySelectorAll(`.${name}-group>input`);
  }
}
