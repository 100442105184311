import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'form' ]

  reset() {
    let form = this.formTarget
    form.reset()
  }
}