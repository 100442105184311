import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['balance']

    connect() {
        this.countSubtotals();
    }

    countSubtotals() {
        const orderRows = document.querySelectorAll(`.order-row`);
        let total_balance = {bonus: 0.0, commissionCny: 0.0, commissionUsdt: 0.0};

        for (let orderRow of orderRows) {
            let genre = this.selectClassInnerText(orderRow, 'genre');
            let balance = this.selectClassInnerText(orderRow, 'balance').trim().split(/\s+/);
            let currency = balance[0];

            if (genre == '佣金' || genre == 'commission') {
                if (currency == '¥') {
                    total_balance.commissionCny += parseFloat(balance[1].replace(/,/g,''));
                } else if (currency == '$') {
                    total_balance.commissionUsdt += parseFloat(balance[1].replace(/,/g,''))
                }
            } else {
                total_balance.bonus += parseFloat(balance[1].replace(/,/g,''))
            }
        }

        this.balanceTarget.innerHTML = `
          <span class="flex flex-col">
            <span>红利 ¥ ${total_balance.bonus.toLocaleString('zh-CN', {minimumFractionDigits: 2})}</span>
            <span>佣金 ¥ ${total_balance.commissionCny.toLocaleString('zh-CN', {minimumFractionDigits: 2})}</span>
            <span>佣金 $ ${total_balance.commissionUsdt.toLocaleString('zh-CN', {minimumFractionDigits: 2})}</span>
          <span>
        `;
    }

    selectClassInnerText(orderRow, attribute) {
        return orderRow.querySelector(`.order-${attribute}`).innerText;
    }
}