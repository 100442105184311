import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  connect() {
    if (this.element.tagName === 'SELECT') return;
    let permissions = document.querySelectorAll("[id^='permission']");
    this.initailChecked(permissions);
    let blockCheckBoxes = document.querySelectorAll("input[id^='all']");
    this.initailChecked(blockCheckBoxes);
  }

  initailChecked(object) {
    for (let element of object) {
      let childCheckBoxes = element.parentElement.nextElementSibling.querySelectorAll("input[type='checkbox']");
      element.checked = this.childCheckBoxesCheckedValue(childCheckBoxes);
    }
  }

  toggleCheckBox(e) {
    let checkedValue = e.target.checked;
    let checkBoxId = e.target.id;
    if (checkBoxId.startsWith('permission')) {
      let parentDiv = document.querySelector(`.${checkBoxId}_pages`);
      let childCheckBoxes = parentDiv.querySelectorAll("input[type='checkbox']");
      childCheckBoxes.forEach(box => box.checked = checkedValue);
    } else {
      let permission = e.target.classList[0]
      let parentCheckBox = document.querySelector(`.${permission}`).firstElementChild.firstElementChild;
      this.toggleParentCheckBox(`.${permission}_pages`, parentCheckBox);
    }
  }

  toggleParentCheckBox(className, parentCheckBox) {
    let childCheckBoxes = document.querySelector(`${className}`).querySelectorAll("input[type='checkbox']");
    let allChecked = this.childCheckBoxesCheckedValue(childCheckBoxes);
    parentCheckBox.checked = allChecked;
  }

  toggleAll(e) {
    let allCheckBoxes = e.target.parentElement.nextElementSibling.querySelectorAll("input[type='checkbox']");
    for (let checkBox of allCheckBoxes) {
      checkBox.checked = e.target.checked;
    }
  }

  childCheckBoxesCheckedValue(childCheckBoxes) {
    for (let checkBox of childCheckBoxes) {
      if (!checkBox.checked) return false;
    }
    return true;
  }

  switchPositionPermissions(e) {
    Rails.ajax({
      type: 'GET',
      url: `/admins/new?id=${e.target.value}`,
      dataType: 'script',
      success: (response) => {
        this.clearCheckBoxes();
        this.replaceCheckBoxes(JSON.parse(response));
      },
      error: function (response) {
        alert(response);
      }
    })
  }

  clearCheckBoxes() {
    let checkBoxes = document.querySelectorAll("input[type='checkbox']");
    for (let checkBox of checkBoxes) {
      checkBox.checked = false;
    }
  }

  replaceCheckBoxes(permissions) {
    permissions.forEach(permission => {
      document.querySelector(`input[value='${permission}']`).checked = true;
    });
    let parentCheckBoxes = document.querySelectorAll("[id^='permission']");
    for (let checkBox of parentCheckBoxes) {
      this.toggleParentCheckBox(`.${checkBox.id}_pages`, checkBox);
    }
  }
}
