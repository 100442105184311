import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static values = {
    alertMsg: String
  }

  copy(event) {
    let copyText = event.target.parentNode.getElementsByTagName('input')[0]
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy")
    alert(this.alertMsgValue)
  }

  copySpan(event) {
    let copyText = event.target.previousElementSibling.textContent
    let textArea = document.createElement("textarea")
    textArea.value = copyText
    document.body.appendChild(textArea)
    textArea.select()
    textArea.setSelectionRange(0, 99999)
    document.execCommand("copy")
    textArea.remove()
    alert(this.alertMsgValue)
  }
}
