import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'sendingType', 'expiredAt' ]
  static values = {
    path: String,
    language: String
  }

  connect() {
    $(document).on('turbolinks:load', () => {
      this.enableSelect2()
      this.switchSelect()
    })
    this.enableSelect2()
    this.switchSelect()
  }

  enableSelect2(multiple = false, tags = false) {
    let path = this.pathValue
    let language = this.languageValue

    $('.select2-container').remove()

    $('.select2').prop('disabled', false)

    $('.select2').select2({
      ajax: {
        delay: 700,
        data: function(params) {
          return {
            user: { username: params.term },
            page: params.page || 1
          }
        },
        transport: function(params, success, _failure) {
          Rails.ajax({
            url: path,
            type: 'GET',
            dataType: 'json',
            data: decodeURIComponent($.param(params.data)),
            success: data => success(data)
          })
        },
        processResults: function(data, params) {
          params.page = params.page || 1
          return {
            results: $.map(data.user, function(user_data) { return { id: user_data.id, text: user_data.username } }),
            pagination: {
              more: (params.page * 50) < data.count
            }
          }
        }
      },
      cache: true,
      multiple: multiple,
      tags: tags,
      tokenSeparators: [',', ' '],
      createTag: function(params) {
        return {
          id: `multiInput-${params.term}`,
          text: params.term,
          newTag: true
        }
      },
      language: language,
      width: '100%'
    })
  }

  switchSelect() {
    switch (this.sendingTypeTarget.value) {
      case '2':
        $('.select2').prop('disabled', true).val(null).trigger('change')
        this.expiredAtTarget.classList.remove('hidden')
        break
      case '0':
        this.enableSelect2()
        this.expiredAtTarget.classList.add('hidden')
        break
      case '1':
        this.enableSelect2(true, true)
        this.expiredAtTarget.classList.add('hidden')
        break
    }
  }
}
