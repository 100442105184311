import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'genreSelect', 'betAmountRequiredTimes', 'expirySelect',
                     'endAt', 'offlineAt', 'applySelect', 'serviceSelection' ]

  connect() {
    this.triggerServices()
    this.switchBetAmountRequiredTimes(this.genreSelectTarget.value === 'rakeback');
    this.switchEndTimeSelect(this.expirySelectTarget.value === 'permanent');

    $(document).on('turbolinks:load', () => {
      this.enableSelect2()
    })
  }

  setBetAmountRequiredTimes(e) {
    this.switchBetAmountRequiredTimes(e.target.value === 'rakeback');
  }

  setEndTimeSelect(e) {
    this.switchEndTimeSelect(e.target.value === 'permanent');
  }

  switchBetAmountRequiredTimes(status) {
    if (status) {
      this.betAmountRequiredTimesTarget.value = 0;
      this.betAmountRequiredTimesTarget.readOnly = true;
    } else {
      this.betAmountRequiredTimesTarget.value = 1;
      this.betAmountRequiredTimesTarget.readOnly = false;
    }
  }

  switchEndTimeSelect(status) {
    if (status) {
      this.endAtTarget.style.display = 'none';
      this.offlineAtTarget.style.display = 'none';
      this.endAtTarget.lastElementChild.required = false;
      this.offlineAtTarget.lastElementChild.required = false;
    } else {
      this.endAtTarget.style.display = 'unset';
      this.offlineAtTarget.style.display = 'unset';
      this.endAtTarget.lastElementChild.required = true;
      this.offlineAtTarget.lastElementChild.required = true;
    }
  }

  enableSelect2() {
    $('#event_promotion_code_ids').select2({
      cache: true,
      tags: true,
      tokenSeparators: [',', ' ']
    });
  }

  triggerServices() {
    if (this.applySelectTarget.value == 'auto') {
      $(this.serviceSelectionTarget).show()
    } else {
      $(this.serviceSelectionTarget).hide()
    }
  }
}
