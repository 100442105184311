import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['amount', 'amountError', 'amountBorder']
  static values = {
    typeGroup: String
  }

  connect() {
    this.changeBonusOption()
    this.verifyAmount()
  }

  changeBonusOption() {
    if (this.typeGroupValue !== 'bonus')
      return

    if ($('#bonus_order_order_type_id option:selected').attr('data-operator') === '-') {
      $('.for-replenishment').hide()
    } else {
      $('.for-replenishment').show()
    }

    if ($('#bonus_order_order_type_id option:selected').attr('data-name') === 'promotion_bonus') {
      $('.game-category').show()
    } else {
      $('.game-category').hide()
    }
  }

  verifyFields(event) {
    if (!this.isAmountValid()) {
      event.preventDefault()
      window.scrollTo(0, 0)
      this.amountTarget.focus()
      showAmountError(false, this.amountErrorTarget, this.amountBorderTarget)
    }
  }

  verifyAmount() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      showAmountError(this.isAmountValid(), this.amountErrorTarget, this.amountBorderTarget)
    }, 800)
  }

  isAmountValid() {
    return parseFloat(this.amountTarget.value) > 0.0
  }
}

function showAmountError(isAmountValid, target, border) {
  if (isAmountValid) {
    target.style.display = 'none'
    target.innerText = ''
    border.classList.remove('border-red-500')
  } else {
    target.style.display = 'block'
    target.innerText = '「交易金额不可为0」'
    border.classList.add('border-red-500')
  }
}