import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['content']
  static values = {
    sortField: String,
    updatePath: String
  }

  connect() {
    this.sortable = Sortable.create(this.contentTarget, {
      animation: 150,
      ghostClass: 'ghost-background',
      handle: '.list-handle'
    })
  }

  saveList() {
    let data = new FormData()

    this.contentTarget.querySelectorAll('tr').forEach(tr => {
      data.append('list[]', tr.getAttribute('data-id'))
    });

    Rails.ajax({
      url: this.updatePathValue,
      type: 'POST',
      data: data,
      success: (response) => {
        location.reload()
      },
      error: function (response) {
        alert(response)
      }
    })
  }
}
