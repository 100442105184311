import { Controller } from "stimulus"
import QRCode from 'easyqrcodejs'

export default class extends Controller {
  static targets = ["qrcode"]
  static values = {
    path: String
  }
  connect(){
    var options = {
      text: this.pathValue,
      logo: document.querySelector("link[rel='shortcut icon']").getAttribute('href')
    };
    new QRCode($(this.qrcodeTargets)[0], options);
  }
}