import { Controller } from 'stimulus'

let inputSelectors = '.editable input'
let optionSelectors = '.editable select'
let radioSelectors = '.editable :radio:not(:checked)'
let editable = false

export default class extends Controller {
  connect() {
    if (!editable) {
      this.switchFields()
    }
  }

  switcher() {
    editable = !editable
    this.switchFields()
  }

  switchFields() {
    $(inputSelectors + ',' + optionSelectors).attr('readonly', !editable)
    $(radioSelectors + ',' + optionSelectors).attr('disabled', !editable)
  }
}

