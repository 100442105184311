import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static values = {
    field: String
  }
  
  connect() {
    let checkboxes = $('td.checkbox')
    let lastChecked = null

    checkboxes.click(function(e) {
      if (!lastChecked) lastChecked = this
      if (e.shiftKey) {
          let start = checkboxes.index(this)
          let end = checkboxes.index(lastChecked)
          for (let checkbox of checkboxes.slice(Math.min(start,end), Math.max(start,end)+ 1)) {
            checkbox.firstElementChild.checked = lastChecked.firstElementChild.checked
          }
      }
      lastChecked = this
    })
  }

  checkAll() {
    let state = document.getElementsByName('checkbox_select_all')[0].checked
    let checkboxes = document.getElementsByName(this.fieldValue)
    for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox')
            checkboxes[i].checked = state
    }
  }

  toggle(e) {
    let checkbox = e.target.firstElementChild
    checkbox.checked = !checkbox.checked
  }
}
