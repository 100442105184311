import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'calculateStartDate', 'calculateEndDate', 'registeredStartDate', 'registeredEndDate' ]
  static values = {
    alertMsg: String,
    days: Number,
    asyncPrompt: Object,
    controllerName: String
  }

  submit(e) {
    if (this.asyncPromptValue.days) {
      this.dateDifference(
        e,
        `已超过${this.asyncPromptValue.days}天(${this.asyncPromptValue.prompt})请使用非同步下载，` +
        `或于${this.asyncPromptValue.days}天(${this.asyncPromptValue.prompt})中搜寻`,
        this.asyncPromptValue.days
      )
    } else {
      this.dateDifference(e, '时间区间只能小于35天(五周)', 35)
    }
  }
  
  dateDifference(e, msg, days) {
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const startDate = new Date(this.calculateStartDateTarget.value)
    const endDate = new Date(this.calculateEndDateTarget.value)
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay))
    let registeredDiffDays = 0

    if (this.controllerNameValue === 'member_registration_statements') {
      const registeredStartDate = new Date(this.registeredStartDateTarget.value)
      const registeredEndDate = new Date(this.registeredEndDateTarget.value)
      registeredDiffDays = Math.round(Math.abs((registeredStartDate - registeredEndDate) / oneDay)) + 1
    }

    if (diffDays > days || registeredDiffDays > days) {
      e.preventDefault()
      alert(msg)
    }
  }
}

