import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['section', 'alert']
  static values = { method: String }

  connect() {
    this.checkReplacePath()
  }

  close(e) {
    this.sectionTarget.classList.remove('is-show');
    e.currentTarget.classList.remove('is-show')
  }

  checkReplacePath() {
    if (this.hasAlertTarget && this.needReplacePath()) {
      history.replaceState(null, document.querySelector('title').innerText, document.referrer)
    }
  }

  needReplacePath() {
    let path = ['/user/member-center/wallet/payment_password']
    if (this.methodValue == 'POST') {
      return path.includes(location.pathname)
    }
  }
}