import {Controller} from 'stimulus'
import $ from "jquery"

export default class extends Controller {
    static targets = ['hiddenExpireAt', 'hiddenSubmit']

    revokeOrder() {
        $('#updateType').val('revoke')
        $('#updateForm').submit()
    }

    distributeOrder() {
        let expire_at = document.getElementById('expire_date').value
        $('#expire_at').val(expire_at)
        $('#updateType').val('distribute')
        $('#updateForm').submit()
    }

    distributeSingleOrder(event) {
        let bonusOrderlink = $(event.target).val()
        let form = document.getElementById('hiddenForm')
        this.hiddenExpireAtTarget.value = document.getElementById('expire_date').value

        form.action = bonusOrderlink
        form.submit()
    }
}