import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'amount', 'fee' ]

  connect() {
    const subtotals = ['amount','fee'];
    for (let subtotal of subtotals) {
      this.countSubtotals(subtotal);
    }
  }

  countSubtotals(subtotal) {
    const orderAmounts = document.querySelectorAll(`.order-${subtotal}`);
    let cnyResult = 0.0;
    let usdtResult = 0.0;
    for(let orderAmount of orderAmounts) {
      orderAmount = orderAmount.textContent.trim().split(/\s+/);
      let currency = orderAmount[0];
      if (orderAmount[0] == '¥') {
        cnyResult = cnyResult + parseFloat(orderAmount[1].replace(/,/g,''));
      } else if (orderAmount[0] == '$') {
        usdtResult = usdtResult + parseFloat(orderAmount[1].replace(/,/g,''));
      }
    }
    this.targets.find(subtotal).innerHTML = `
      <span class="flex flex-col">
        <span>$ ${usdtResult.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</span>
        <span>¥ ${cnyResult.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</span>
      <span>
    `;
  }
}
