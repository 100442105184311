import { Controller } from "stimulus"
import Swiper, {Pagination, Autoplay, Lazy} from "swiper"

export default class extends Controller {
  connect(){
    Swiper.use([Pagination, Autoplay, Lazy])

    var swiper = new Swiper('.swiper-container', {
      spaceBetween: 30,
      centeredSlides: true,
      loop : true,
      lazy: true,
      speed: 2000,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}