import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['sidebarList']

  connect() {
    let menus = document.querySelectorAll('#sidebar-list>li');
    for (let menu of menus) {
      if (menu.lastElementChild.childElementCount === 0) {
        menu.lastElementChild.innerHTML = `<li>
                                             <a href="javascript:void(0)">
                                               <i class="fas fa-times"></i>
                                               <span>您无浏览权限</span>
                                             </a>
                                           </li>`;
      }
    }
  }

  slide(){
    $(this.sidebarListTarget).animate({width: 'toggle'});
  }
  
  toggle(e){
    let toggleButton = $(e.target).closest('button');
    if (!toggleButton.length) return;
    let buttonClasses = toggleButton[0].classList;
    if(buttonClasses && jQuery.inArray('sidebar-toggle', buttonClasses) !== -1) {
      toggleButton.toggleClass(['text-gray-400', 'text-white']);
      toggleButton.children('span').last().toggleClass('rotate-180');
      toggleButton.next().slideToggle();
    }
  }
}
