import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['register', 'first_deposit']

  connect() {
    let target = this.element.target.value;
    if (this.element.target.value == 'register') this.toggleRegisterSelector(true);
    if (this.element.target.value == 'first_deposit') this.toggleFirstDepositSelector(true);
  }

  showTargetInput(e) {
    let target = e.target.value;
    if (target == 'register') {
      this.toggleFirstDepositSelector(false);
      this.toggleRegisterSelector(true);
    } else if (target == 'first_deposit') {
      this.toggleRegisterSelector(false);
      this.toggleFirstDepositSelector(true);
    } else {
      this.toggleRegisterSelector(false);
      this.toggleFirstDepositSelector(false);
    }
  }

  toggleRegisterSelector(value) {
    this.registerTarget.hidden = !value;
    this.element.registered_from.required = value;
    this.element.registered_to.required = value;
    if (value == false) {
      this.element.registered_from.value = '';
      this.element.registered_to.value = '';
    }
  }

  toggleFirstDepositSelector(value) {
    this.first_depositTarget.hidden = !value;
    this.element.first_deposit_from.required = value;
    this.element.first_deposit_to.required = value;
    if (value == false) {
      this.element.first_deposit_from.value = '';
      this.element.first_deposit_to.value = '';
    }
  }
}
