import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['settleLink', 'settleButton']
  static values = {
    disableText: String,
    messageText: String,
    translationText: String
  }

  disable(event) {
    event.preventDefault();

    if (confirm(`${this.messageTextValue} ${this.translationTextValue}`)) {
      this.settleLinkTarget.disabled = true;
      this.settleLinkTarget.textContent = this.disableTextValue;
      this.settleLinkTarget.style.cursor = 'wait';
      this.settleButtonTarget.click();
    }
  }
}
