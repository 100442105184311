import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['batchFields', 'userInput', 'phoneInput']

  getPhoneNumber(e) {
    Rails.ajax({
      type: 'GET',
      url: `${this.element.action}?username=${e.target.value}`,
      dataType: 'json',
      success: (res) => {
        let phoneInput = e.target.nextElementSibling
        phoneInput.placeholder = res.message
        phoneInput.value = res.message
      },
      error: (err) => {
        alert(err)
      }
    })
  }

  addUserPhone(e) {
    let addButton = e.currentTarget
    let batchFields = this.batchFieldsTarget
    let userInput = this.userInputTarget.cloneNode(false)
    let colonPhoneInput = this.phoneInputTarget.cloneNode(false)
    userInput.required = false
    colonPhoneInput.required = false
    userInput.value = null
    colonPhoneInput.value = null
    colonPhoneInput.placeholder = '' 
    batchFields.insertBefore(userInput, addButton)
    batchFields.insertBefore(colonPhoneInput, addButton)
  }
}
