import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    let sort = [1, 2, 3, 4]
    for (let n of sort) {
      console.log(n)
      let events = document.querySelectorAll(`tr[data-sort="${n}"]`)
      console.log(events);
      events.forEach(event => {
        this.scope.element.appendChild(event)
      })
    }
  }
}
