import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list"]

  connect(){
    this.listTarget.children[0].classList.add('is-acted')
  }
  filter(e){
    const actedItem = this.listTarget.querySelector('.is-acted')
    if(actedItem === null){
      e.currentTarget.classList.add('is-acted')
    }else{
      actedItem.classList.remove('is-acted')
      e.currentTarget.classList.add('is-acted')
    }
  }
}
