import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['bonusAmount']

    connect() {
        this.countSubtotals('bonusAmount');
    }

    countSubtotals(subtotal) {
        const orderAmounts = document.querySelectorAll(`.order-${subtotal}`);
        let cnyResult = 0.0;
        let usdtResult = 0.0;
        for (let orderAmount of orderAmounts) {
            orderAmount = orderAmount.textContent.trim().split(/\s+/);
            if (orderAmount[0] == '¥') {
                cnyResult = cnyResult + parseFloat(orderAmount[1].replace(/,/g, ''));
            } else if (orderAmount[0] == '$') {
                usdtResult = usdtResult + parseFloat(orderAmount[1].replace(/,/g, ''));
            }
        }
        this.targets.find(subtotal).innerHTML = `
      <span class="flex flex-col">
        <span>$ ${usdtResult.toLocaleString('zh-CN', {minimumFractionDigits: 2})}</span>
        <span>¥ ${cnyResult.toLocaleString('zh-CN', {minimumFractionDigits: 2})}</span>
      <span>
    `;
    }
}
