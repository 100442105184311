import { Controller } from 'stimulus'
import $ from 'jquery'
import Swiper, {Pagination, Navigation, Lazy} from "swiper"

export default class extends Controller {
    connect() {
        $('.question-title').click(function() {
            if($(this).parent("li").hasClass("is-open")) {
                $(this).parent("li").removeClass("is-open")
            } else {
                $(this).parent("li").addClass("is-open")
            }
        })

        Swiper.use([Pagination, Navigation, Lazy])

        var swiper = new Swiper('.page-help-center .swiper-container', {
            lazy: true,
            pagination: {
                el: '.swiper-pagination'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            observer: true,
            observeParents: true
        });

    }
}
