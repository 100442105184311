import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    connect() {
        $( document ).ready(function() {
            var ua = navigator.userAgent.toLowerCase();
                var isIos = (ua.indexOf('iphone') != -1) || (ua.indexOf('ipad') != -1);
                if(isIos){
                    $('.hide-in-android').removeClass('hide-now');
                }else{
                    $('.hide-in-ios').removeClass('hide-now');
                }
        });
    }
}
