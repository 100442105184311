import {Controller} from 'stimulus'
import consumer from "../channels/consumer";

export default class extends Controller {
    connect() {
        this.channel = consumer.subscriptions.create('NotificationChannel', {
            connected: this._cableConnected.bind(this),
            disconnected: this._cableDisconnected.bind(this),
            received: this._cableReceived.bind(this),
        })
    }

    _cableConnected() {
        // Called when the subscription is ready for use on the server
    }

    _cableDisconnected() {
        // Called when the subscription has been terminated by the server
    }

    _cableReceived(data) {
        alert(data.message)
    }
}
