import {Controller} from 'stimulus'
import Rails from "@rails/ujs";
import $ from "jquery";

export default class extends Controller {
    removeRow(event) {
        let row = $(event.target).closest('tr')
        $(row).fadeOut(500, function () {
            $(this).remove()
        })
    }

    changeCategory(event) {
        let category = $('option:selected', event.target).attr('data-category')
        let row = $(event.target).closest('tr')
        row.find('.category').val(category)
    }

    submitResult(event) {
        const response = event.detail[2]
        let html = $.trim(response.response) ? JSON.parse(response.response) : ''
        let platform_table = '#' + $(event.target).attr('data-platform')

        $(platform_table).html(html)
    }

    updateSchedule(event) {
        event = $(event.target)
        let rows = event

        if (event.attr('id') === 'bulk_update') {
            rows = $("input[name='ids[]']:checked")
        }

        rows.each(function() {
            $(this).parent().siblings('.status').html('<i class="fas fa-sync-alt fa-spin text-yellow-600">')
            $(this).parent().siblings('.update-button, .checkbox').html('')
            $(this).remove()
        });
    }

    showError(event) {
        alert(event.detail[0])
    }

    pull_result(event) {
        let status = event.detail[0].status
        let response = JSON.parse(event.detail[0].response)
        let message = $(event.target).children('.pull-message')
        let successHtml = $.isEmptyObject(response) ?
            '<i class="fas fa-check-circle text-green-500 h-full"></i>' :
            '<span class="text-sm text-gray-500">' + response + '</span>'
        let failHtml = $.isEmptyObject(response) ?
            '<i class="fas fa-times-circle text-red-500 h-full"></i>' :
            '<span class="text-sm text-gray-500">' + response + '</span>'
        if (status === 200) {
            message.show().html(successHtml).fadeOut(5000)
        } else {
            message.show().html(failHtml).fadeOut(5000)
        }

    }
}
