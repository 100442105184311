import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'name', 'nameError', 'cardNumber', 'cardNumberError', 'bankBranch', 'bankBranchError' ]

  initialize() {
    this.nameRegex = /^[\u3000\u3400-\u4DBF\u4E00-\u9FFF]+\u00B7?[\u3000\u3400-\u4DBF\u4E00-\u9FFF]+$/
    this.bankBranchRegex = /^[\u3000\u3400-\u4DBF\u4E00-\u9FFF]+$/
    this.cardNumberRegex = /^\d{10,19}$/
  }

  verifyName() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (!this.isValidName()) {
        showNameError(true, this.nameErrorTarget)
      } else {
        showNameError(false, this.nameErrorTarget)
      }
    }, 800)
  }

  verifyBankBranch() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (!this.isValidBankBranch()) {
        showBankBranchError(true, this.bankBranchErrorTarget)
      } else {
        showBankBranchError(false, this.bankBranchErrorTarget)
      }
    }, 800)
  }

  verifyCardNumber() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (!this.isValidCardNumber()) {
        showCardNumberError(true, this.cardNumberErrorTarget)
      } else {
        showCardNumberError(false, this.cardNumberErrorTarget)
      }
    }, 800)
  }

  verifyFields(event) {
    if (!this.isValidName() && !this.isValidCardNumber() && !this.isValidBankBranch()) {
      event.preventDefault()
      window.scrollTo(0, 0)
      this.nameTarget.focus()
      showNameError(true, this.nameErrorTarget)
      showBankBranchError(true, this.bankBranchErrorTarget)
      showCardNumberError(true, this.cardNumberErrorTarget)
    }  else if (!this.isValidName()) {
      event.preventDefault()
      window.scrollTo(0, 0)
      this.nameTarget.focus()
      showNameError(true, this.nameErrorTarget)
    } else if (!this.isValidCardNumber()) {
      event.preventDefault()
      window.scrollTo(0, 0)
      this.cardNumberTarget.focus()
      showCardNumberError(true, this.cardNumberErrorTarget)
    } else if (!this.isValidBankBranch()) {
      event.preventDefault()
      window.scrollTo(0, 0)
      this.bankBranchTarget.focus()
      showBankBranchError(true, this.bankBranchErrorTarget)
    }
  }

  isValidName() {
    return this.nameRegex.test(this.nameTarget.value)
  }

  isValidBankBranch() {
    return this.bankBranchRegex.test(this.bankBranchTarget.value)
  }

  isValidCardNumber() {
    return this.cardNumberRegex.test(this.cardNumberTarget.value)
  }
}

function showNameError(isValid, target) {
  if (isValid) {
    target.style.display = 'block'
    target.innerText = '请输入中文或正确姓名，最少2字'
  } else {
    target.style.display = 'none'
    target.innerText = ''
  }
}

function showBankBranchError(isValid, target) {
  if (isValid) {
    target.style.display = 'block'
    target.innerText = '银行支行只能输入中文'
  } else {
    target.style.display = 'none'
    target.innerText = ''
  }
}

function showCardNumberError(isValid, target) {
  if (isValid) {
    target.style.display = 'block'
    target.innerText = '银行卡号最少10位，最多19位'
  } else {
    target.style.display = 'none'
    target.innerText = ''
  }
}
