import { Controller } from 'stimulus'
import Swiper, {Pagination, Navigation} from "swiper"

export default class extends Controller {
    connect() {
        Swiper.use([Pagination, Navigation])

        var swiper = new Swiper('.page-history-detail .swiper-container', {
            slidesPerView: 'auto',
            centeredSlides: true,
            autoHeight: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false
            },
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        });
    }
}