import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['changed', 'original', 'revised']

    connect() {
        this.countSubtotals();
    }

    countSubtotals() {
        const orderRows = document.querySelectorAll(`.order-row`);
        let attributes = ['changed', 'original', 'revised'];
        let orders = {
            changed: {bonusCny: 0.0, bonusUsdt: 0.0, commissionCny: 0.0, commissionUsdt: 0.0},
            original: {bonusCny: 0.0, bonusUsdt: 0.0, commissionCny: 0.0, commissionUsdt: 0.0},
            revised: {bonusCny: 0.0, bonusUsdt: 0.0, commissionCny: 0.0, commissionUsdt: 0.0}
        };

        for (let orderRow of orderRows) {
            let genre = this.selectClassInnerText(orderRow, 'genre');
            let orderInnertexts = this.orderInnerTexts(orderRow, attributes);
            let currency = orderInnertexts['changed'][0];

            if (genre == '佣金' || genre == 'commission') {
                if (currency == '¥') {
                    orders = this.addOrder(orders, orderInnertexts, 'commissionCny');
                } else if (currency == '$') {
                    orders = this.addOrder(orders, orderInnertexts, 'commissionUsdt');
                }
            } else {
                if (currency == '¥') {
                    orders = this.addOrder(orders, orderInnertexts, 'bonusCny');
                } else if (currency == '$') {
                    orders = this.addOrder(orders, orderInnertexts, 'bonusUsdt');
                }
            }
        }

        for (var key in orders) {
            this.setSubtotal(orders[key], key);
        }
    }

    selectClassInnerText(orderRow, attribute) {
        return orderRow.querySelector(`.order-${attribute}`).innerText;
    }

    addOrder(orders, orderInnertexts, attribute) {
        for (var key in orders) {
            orders[key][attribute] += parseFloat(orderInnertexts[key][1].replace(/,/g, ''))
        }
        return orders;
    }

    orderInnerTexts(orderRow, attributes) {
        let results = {changed: null, original: null, revised: null};
        for (let attribute of attributes) {
            results[attribute] = this.selectClassInnerText(orderRow, attribute).trim().split(/\s+/);
        }
        return results;
    }

    setSubtotal(results, attribute) {
        this.targets.find(attribute).innerHTML = `
      <span class="flex flex-col">
        <span>红利 ¥ ${results.bonusCny.toLocaleString('zh-CN', {minimumFractionDigits: 2})}</span>
        <span>红利 $ ${results.bonusUsdt.toLocaleString('zh-CN', {minimumFractionDigits: 2})}</span>
        <span>佣金 ¥ ${results.commissionCny.toLocaleString('zh-CN', {minimumFractionDigits: 2})}</span>
        <span>佣金 $ ${results.commissionUsdt.toLocaleString('zh-CN', {minimumFractionDigits: 2})}</span>
      <span>
    `;
    }

}
