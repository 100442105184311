import {Controller} from 'stimulus'

export default class extends Controller {
    static values = {
        translation: String
    }

    batchRevoke() {
        if (confirm(this.translationValue)) {
            document.getElementById('bulk-update').submit();
        } else {
            return false;
        }
    }
}
