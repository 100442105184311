import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    $(this.element).children(`.filter-item[href$='${location.pathname}']`).addClass('is-acted is-scroll-to-acted')
    $(`.nav-item[href$='${location.pathname}']`).addClass('is-acted')
    $(`.footer-item[href$='${location.pathname}']`).addClass('is-acted')
    $( document ).ready(function() {
      $('.filter-item.is-acted').removeClass('is-scroll-to-acted')
      $('.deposit-method-sub').removeClass('is-scroll-to-acted')
    });
  }
}
