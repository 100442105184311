import { Controller } from 'stimulus'
import $ from 'jquery'
import '../scripts/jquery.countTo.js'
import Rails from "@rails/ujs"

export default class extends Controller {
    connect() {
        $(".list-item.url").addClass("progressing")
        this.generate_time()
    }

    generate_time() {
        let sign = this.getSign
        let randomInRange = this.randomInRange

        $('.limit').each(function () {
            let random_float = randomInRange(1.00, 3.00)
            $(this).countTo({
                to: random_float,
                decimals: 2,
                onComplete: function () {
                    this.siblings('.sign').addClass(sign(random_float))
                    $(".list-item.url").removeClass("progressing")
                }
            });
        });
    }

    randomInRange(min, max) {
        return (Math.random() * (max - min) + min).toFixed(2)
    }

    getSign(time) {
        let sign = 'green'
        if (time > 1.00) {
            sign = time > 2.0 ? 'red' : 'yellow'
        }
        return sign
    }

    reload() {
        $(".list-item.url").addClass("progressing")
        $(".sign").removeClass("green yellow red")
        this.generate_time()
    }
}