import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import Cookies from 'js-cookie'

export default class extends Controller {
  static values = {
    brandName: String
  }

  initialize() {
    if (localStorage) {
      this.setWithExpiry()
    }
  }

  itemName() {
    return `${this.brandNameValue}Visits`
  }

  setWithExpiry() {
    const now = new Date()
    const item = {
      value: this.getWithExpiry(this.itemName()).concat(window.location.href),
      expiry: now.getTime() + this.ttl(),
    }
    localStorage.setItem(this.itemName(), JSON.stringify(item))
  }

  getWithExpiry() {
    const itemStr = localStorage.getItem(this.itemName())
    if (!itemStr) return []

    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      localStorage.removeItem(this.itemName())
      return []
    }
    return item.value
  }

  ttl() {
    return 24 * 60 * 60 * 1000
  }

  goToChat(e) {
    const link = e.currentTarget.nextElementSibling
    const url = new URL(link.href)
    const fingerPrint = Cookies.get('finger_print')
    url.searchParams.append('visited_pages', this.getWithExpiry())
    if (fingerPrint) url.searchParams.append('finger_print', fingerPrint)

    link.href = url
    link.click()
  }
}
