import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ['orders']
  static values = {
    username: String,
    position: String,
    adminStart: Boolean,
    adminDecline: Boolean,
    adminShow: Boolean,
    adminEdit: Boolean,
    adminPaid: Boolean
  }

  connect() {
    this.channel = consumer.subscriptions.create('WithdrawChannel', {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    })
    this.checkButtonPermission()
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    let action = data.action
    let orderId = data.order.id
    let order = `#order-${orderId}`
    let message = data.message
    let parser = new DOMParser()
    let content = parser.parseFromString(data.html, 'text/xml')
    let serializer = new XMLSerializer()

    if (location.pathname === '/withdraws/inspects') {
      this.displayFlashMessage(message)
    }

    if (action === 'new_withdraw') {
      this.ordersTarget.insertAdjacentHTML('afterbegin', serializer.serializeToString(content))
    } else if (action === 'decline_inspect') {
      $(`${order}`).remove()
    } else {
      $(`${order}`).html(content.querySelector('tr').innerHTML)
    }

    this.checkButtonPermission()
    console.log(message)
  }

  checkButtonPermission() {
    if (!this.mayStart()) {
      $('.inspect-start').hide()
    }

    if (!this.mayEdit()) {
      $('.inspect-press, .inspect-edit').hide()
    } else {
      this.checkInspector('.inspect-edit', 'risk_control_manager')
    }

    if (!this.mayDecline()) {
      $('.inspect-cancel').hide()
    } else {
      this.checkInspector('.inspect-cancel')
    }

    if (!this.mayPaid()) {
      $('.inspect-paid').hide()
    }

    if (!this.mayShow()) {
      $('.detail').hide()
    }
  }

  displayFlashMessage(message) {
    let flash = document.querySelector("[data-controller='admin--flash']");
    flash.innerHTML = `<div class="flex flex-row justify-center h-auto bg-green-100 p-3 text-green-900 cursor-pointer" data-action="click->admin--flash#close">
        <span><i class="fas fa-check-circle text-green-500"></i> ${message}</span>
        <span class="text-green-700 ml-auto"><i class="fas fa-times"></i></span>
      </div>`;
  }

  checkInspector(selector, specialPosition = '') {
    let username = this.usernameValue
    let position = this.positionValue

    $(selector).each(function () {
      let buttonBlock = $(this).parent('td')
      let inspector = buttonBlock.siblings('.inspector').text()
      let mayPress = buttonBlock.attr('may-press')
      if (mayPress === 'true') {
        if (inspector !== username && (!specialPosition || position !== specialPosition)) {
          $(this).hide()
        }
      }
    })
  }

  mayStart() {
    return this.adminStartValue
  }

  mayDecline() {
    return this.adminDeclineValue
  }

  mayShow() {
    return this.adminShowValue
  }

  mayEdit() {
    return this.adminEditValue
  }

  mayPaid() {
    return this.adminPaidValue
  }
}
