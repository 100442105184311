import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    connect() {
        $( document ).ready(function() {
            $('.filter-item.is-acted').removeClass('is-scroll-to-acted')
        });
    }
}
