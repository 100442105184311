import consumer from "./consumer"

consumer.subscriptions.create("WithdrawChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (Notification.permission === 'granted' && data.action === 'new_withdraw') {
      let options = {
        body: data.message,
        tag: data.action + data.order.id
      }
      sendNotification(data.title, options)
    } else {
      Notification.requestPermission().then(function (result) {});
    }
  }
});

document.addEventListener('turbolinks:load', () => {
  console.log(consumer);
  if (consumer.connection.disconnected) {
    console.log(consumer);
    consumer.connection.webSocket.onerror = function () {
      // Error handling goes here. Fallback to polling, perhaps.
      if (Notification.permission === 'granted') {
        let options = {
          body: '请联络技术部门'
        }
        sendNotification('ActionCable 连接错误', options)
      } else {
        Notification.requestPermission().then(function (result) {});
      }
    };
  }
});

function sendNotification(title, options) {
  let icon = document.querySelector("link[rel~='icon']");
  options.icon = icon.href
  new Notification(title, options).onclick = function (event) {
    parent.focus();
    window.focus();
    this.close();
  }
}
