import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static values = {
    path: String
  }

  connect() {
    this.getBalances(this.pathValue);
  }

  getBalances() {
    Rails.ajax({
      type: 'GET', 
      url: this.pathValue,
      dataType: 'script',
      success: (response) => {},
      error: (response) => {
        alert(response);
      }
    })
  }
}
