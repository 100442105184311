import {Controller} from 'stimulus'
import Rails from '@rails/ujs'
import $ from "jquery";

export default class extends Controller {

    connect() {
        this.adjustButton(false)
    }

    rowUp(event) {
        this.updateSequence(event, 'previous')
    }

    rowDown(event) {
        this.updateSequence(event, 'next')
    }

    updateSequence(event, nextPrevRow) {
        let row = $(event.target).parents("tr:first")
        let eventId = row.data('id')
        let swapRow = nextPrevRow == 'previous' ? row.prev() : row.next()
        let swapEventId = swapRow.data('id')
        let adjustButton = this.adjustButton

        if (eventId == null || swapEventId == null) return;
        $(event.target).css('cursor', 'wait')
        adjustButton(true)

        Rails.ajax({
            url: `/site/events/${eventId}/update_sequence?swap_event_id=${swapEventId}`,
            type: 'POST',
            dataType: 'json',
            success: function (response) {
                if (response == true) {
                    nextPrevRow == 'previous' ? row.insertBefore(swapRow) : row.insertAfter(swapRow)
                } else {
                    let flash = document.querySelector('[data-controller="admin--flash"]')
                    flash.innerHTML = `
                        <div class="flex flex-row justify-center h-auto bg-red-100 p-3 text-red-900 cursor-pointer" data-action="click->admin--flash#close">
                        <span><i class="fas fa-times-circle text-red-500"></i> ${response}</span>
                        <span class="text-red-700 ml-auto"><i class="fas fa-times"></i></span>
                        </div>`
                }
                adjustButton(false)
                $(event.target).css('cursor', 'pointer')
            }
        })
    }

    adjustButton(state) {
        let buttonsDirections = document.querySelectorAll(`.up-direction, .down-direction`)
        let upButtonFirst = buttonsDirections[0]
        let downButtonLast = buttonsDirections[buttonsDirections.length - 1]

        Array.from(buttonsDirections).forEach(button => {
            let buttonStyle = button == upButtonFirst || button == downButtonLast ? 'none' : ''

            button.disabled = state
            button.style.display = buttonStyle
        })
    }
}
