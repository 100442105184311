import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import $ from "jquery";

export default class extends Controller {
  static targets = ['casinoPlatforms']
  static values = {
    path: String
  }

  connect(){
    this.getData();
  }

  refreshBalances(event){
    this.getData(event);
    Array.from(this.casinoPlatformsTarget.children).forEach((casinoPlatform) => {
      casinoPlatform.lastElementChild.innerHTML = '<span class="text-gray-400">更新中请稍候...</span>';
    });
  }

  refreshRequiredBetAmount(event) {
    this.getData(event);
    $('.required_bet_amount').html('更新中请稍候...')
  }

  getData(event){
    let refreshType = event ? $(event.target).attr('data-refresh-type') : 'balance'
    Rails.ajax({
      type: 'GET',
      url: this.pathValue + '?refresh_type=' + (refreshType ?? ''),
      dataType: 'script',
      success: (response) => {
        switch (refreshType) {
          case 'required_bet_amount':
            $('.required_bet_amount').html(JSON.parse(response));
            break;
          case 'balance':
            this.showBalances(response);
            break;
        }
      },
      error: function(response) {
        alert(response);
      }
    })
  }

  showBalances(data){
    const casinoPlatforms = JSON.parse(data)
    casinoPlatforms.forEach(casinoPlatform => {
      let platformBalance = (casinoPlatform.balance * 1 || 0).toLocaleString('zh-CN', { minimumFractionDigits: 2 });
      document.querySelector(`#platform_balance_${casinoPlatform.service_name}`).innerHTML =  `<div class="text-gray-500">${platformBalance}</div>`;
    });
  }
}
