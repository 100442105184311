import {Controller} from 'stimulus'
import $ from 'jquery'
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ['isPublic', 'promotionCode']
    static values = {
        action: String,
        path: String
    }

    connect() {
        switch (this.actionValue) {
            case 'new':
                this.promotionCodeTarget.disabled = this.isPublicTarget.checked
                break
            case 'edit':
                this.getPromotionCode()
                break
        }
    }

    toggle(e) {
        this.promotionCodeTarget.disabled = e.target.checked
    }

    toggleAdmin(e) {
        this.getPromotionCode(e.target.value)
    }

    getPromotionCode(admin) {
        Rails.ajax({
            type: 'GET',
            url: this.pathValue + '?admin_id=' + (admin ?? ''),
            dataType: 'script',
            success: (response) => {
                let data = response
                let target = this.promotionCodeTarget
                target.innerHTML = ''

                $.each(data, function (item, value) {
                    const option = document.createElement("option")
                    option.value = value[1]
                    option.text = value[0]
                    target.appendChild(option)
                })
            },
            error: function (response) {
                alert(response);
            }
        })
    }
}
