import {Controller} from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = [
        'file', 'excelTable', 'excelSummary',
        'filename', 'totalData', 'totalAmount',
        'ordersForm'
    ]

    connect() {
        $('.game-category').hide()
    }

    display_game_category(event) {
        if ($(event.target).find('option:selected').attr('data-name') === 'promotion_bonus') {
            $('.game-category').show()
        } else {
            $('.game-category').hide()
        }
    }

    preview() {
        const files = this.fileTarget.files;
        if (files.length <= 0) return alert('请先选择一个文件');
        var file = files[0];
        var XLSX = require("xlsx");
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (event) => {
            var data = new Uint8Array(reader.result);
            var work_book = XLSX.read(data, {type: 'array'});
            var sheet_names = work_book.SheetNames;
            var sheet_data = XLSX.utils.sheet_to_json(work_book.Sheets[sheet_names[0]], {header: 1});
            if (sheet_data.length > 0) {
                var headers = sheet_data.shift();
                var expected_headers = ['account', 'amount', 'note'];
                var header_contained = false;
                for (var i = 0; i < expected_headers.length; i++) {
                    header_contained = headers.includes(expected_headers[i]);
                }
                if (!header_contained) return alert('档案格式错误');
                var table_output = '<table class="table-striped" id="excel-preview"><thead>';
                for (var cell = 0; cell < headers.length; cell++) {
                    table_output += '<th>' + headers[cell] + '</th>';
                }
                table_output += '</thead>'

                table_output += '<tbody>'
                var totalData = sheet_data.length;
                var totalAmount = 0.0;
                for (var row = 0; row < totalData; row++) {
                    table_output += '<tr>';
                    for (var cell = 0; cell < sheet_data[row].length; cell++) {
                        table_output += '<td>' + sheet_data[row][cell] + '</td>';
                        if (cell == 1) totalAmount += sheet_data[row][cell];
                    }
                    table_output += '</tr>';
                }
                table_output += '</tbody>'

                table_output += '</table>';

                this.filenameTarget.innerText = file.name;
                this.totalDataTarget.innerText = totalData;
                this.totalAmountTarget.innerText = totalAmount;
                this.excelTableTarget.innerHTML = table_output;
                this.excelSummaryTarget.classList.remove('hidden');
            }
        }
    }

    cancel() {
        this.filenameTarget.innerText = '';
        this.totalDataTarget.innerText = '';
        this.totalAmountTarget.innerText = '';
        this.excelTableTarget.innerHTML = '';
        this.excelSummaryTarget.classList.add('hidden');
    }

    submitForm() {
        this.ordersFormTarget.submit();
    }
}
