import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    let adminUsernameInput = document.querySelector('#admin_user_username');
    let agentUsernameInput = document.querySelector('#agent_user_username');
    if (adminUsernameInput) adminUsernameInput.value = adminUsernameInput.value.replace('admin_', '');
    if (agentUsernameInput) agentUsernameInput.value = agentUsernameInput.value.replace('agent_', '');
  }

  validateForm(event) {
    let validate_fields = $.merge($('input[data-validate]'), $('select[data-validate]'))

    for (let i = 0; i < validate_fields.length; i++) {
      let field = validate_fields[i]
      this.validateByType(field);

      if ($(field).parent().siblings('.error-message').html().length > 0) {
        window.scrollTo(0, 0)
        event.preventDefault()
      }
    }
  }

  validateField(event) {
    let validate_field = event.target
    this.validateByType(validate_field)
  }

  validateByType(event) {
    let type = $(event).attr('data-validate').split(' ')
    let message = []
    let regex = ''
    let withdrawMin = $(event).attr('data-withdraw-min')
    let withdrawMax = $(event).attr('data-withdraw-max')

    let errorDiv = $(event).parent().siblings('.error-message')
    $.each(type, function (_item, value) {
      switch (value) {
        case 'required':
          if ($(event).val().length === 0) {
            message.push('不可空白')
          }
          break
        case 'required-username':
          regex = new RegExp(/^[0-9a-zA-Z]{3,10}$/)
          if (!regex.test($(event).val())) {
            message.push('仅可输入英文与数字，长度为3-10码')
          }
          break
        case 'required-password':
          if ($(event).val().length < 6 || $(event).val().length > 18) {
            message.push('不可空白,请输入6-18位')
          }
          break
        case 'phone-number':
          regex = new RegExp(/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/)
          if (!regex.test($(event).val())) {
            message.push('格式不符合')
          }
          break
        case 'number-only':
          regex = new RegExp(/[^0-9\.]/)
          if ($(event).val() != '' && regex.test($(event).val())) {
            message.push('须为数字')
          }
          break
        case 'amount-integer':
          regex = new RegExp(/^[0-9]+\.[0-9]*$/)
          if ($(event).val() != '' && regex.test($(event).val())) {
            message.push('请输入正整数')
          }
          break
        case 'bank-branch':
          regex = new RegExp(/^[\u4e00-\u9fa50-9]+$/)
          if (!regex.test($(event).val())) {
            message.push('请输入中文或数字')
          }
          break
        case 'withdraw-amount':
          if ($(event).val() < Number(withdrawMin) || $(event).val() > Number(withdrawMax)) {
            message.push(`每笔金额需介于${withdrawMin}～${withdrawMax}之间。`)
          }
          break
        case 'withdraw-amount-usdt':
          if ($(event).val() < Number(withdrawMin)) {
            message.push(`每笔金额需介于${withdrawMin}～无上限(no limit)。`)
          }
          break
        case 'required-usdt-address':
          let regexErc = new RegExp(/^0x[a-fA-F0-9]{40}$/)
          let regexTrc = new RegExp(/^T[A-Za-z1-9]{33}$/)
          if (($('#blockchain_address_main_coin').val() === 'ERC20' && !regexErc.test($(event).val())) || ($('#blockchain_address_main_coin').val() === 'TRC20' && !regexTrc.test($(event).val()))) {
            message.push('格式不符合')
          } else if ($('#blockchain_address_main_coin').val() === '') {
            message.push('请先选择区块链')
          }
          break
      }
    })

    if (message.length !== 0) {
      errorDiv.html(message.join(',')).show()
    } else {
      errorDiv.html('').hide()
    }
  }
}
