import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {

    changeTab(e){
        let id = e.currentTarget.getAttribute("id")
        $('.btn-box').children('a').removeClass('is-acted')
        $('.content-wrap').removeClass('is-show')
        if(id == 'basic') {
            $('.p2p_basic').addClass('is-acted')
            $('.content_wrap_1').addClass('is-show')
        } else if(id == 'deposit') {
            $('.p2p_deposit').addClass('is-acted')
            $('.content_wrap_2').addClass('is-show')
        } else if(id == 'withdrawal') {
            $('.p2p_withdrawal').addClass('is-acted')
            $('.content_wrap_3').addClass('is-show')
        } else if(id == 'appeal') {
            $('.p2p_appeal').addClass('is-acted')
            $('.content_wrap_4').addClass('is-show')
        }
    }
}