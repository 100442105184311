import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  vipTableStorageSwitch() {
    if ($(".is-too-many").hasClass("is-hidden")) {
      $(".is-too-many").removeClass("is-hidden")
      $(".more-btn.is-show-more").addClass("is-hidden")
      $(".more-btn.is-hide-more").removeClass("is-hidden")
    } else {
      $(".is-too-many").addClass("is-hidden")
      $(".more-btn.is-show-more").removeClass("is-hidden")
      $(".more-btn.is-hide-more").addClass("is-hidden")
    }
  }
  promoVipTableSwitch() {
    if ($(".too-many").hasClass("hide-now")) {
      $(".too-many").removeClass("hide-now")
    } else {
      $(".too-many").addClass("hide-now")
    }
  }
}
