import {Controller} from 'stimulus'
import $ from "jquery";
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['paymentPassword', 'paymentPasswordError', 'hiddenPaymentPassword', 'hiddenSubmit']
    static values = {
        path: String
    }

    verifyPaymentPassword(event) {
      let message = []
      let errorDiv = $(this.paymentPasswordErrorTarget)
      let password = this.paymentPasswordTarget.value
      if (password.length < 6 || password.length > 18) {
        message.push('不可空白,请输入6-18位')
      } else {
        this.hiddenPaymentPasswordTarget.value = password
        this.hiddenSubmitTarget.click()
      }
      if (message.length !== 0) {
        errorDiv.html(message.join(',')).show()
      } else {
        errorDiv.html('').hide()
      }
    }
}
