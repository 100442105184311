import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static values = {
    type: String,
    platform: String
  }

  openManualTransferPop() {
    // 開啟最外層
    $(".manual_transfer_pop").addClass("is-show");
    // 開啟內層轉出及轉入
    if (this.typeValue === "out") {
      $(".manual_transfer_out_list").addClass("is-show");
    }
    if (this.typeValue === "in") {
      $(".manual_transfer_in_list").addClass("is-show");
    }
  }

  closeManualTransferPop() {
    // 關閉最外層
    $(".manual_transfer_pop").removeClass("is-show");
    // 關閉內層轉出及轉入
    $(".manual_transfer_out_list").removeClass("is-show");
    $(".manual_transfer_in_list").removeClass("is-show");
  }

  manualTransferList(e) {
    let option = e.currentTarget
    let type = this.typeValue

    $('.manual_transfer_' + type + '_list .item').removeClass("is-acted")
    option.classList.add('is-acted')

    let platform = option.getAttribute('data-platform')
    let src = option.querySelector('use').getAttribute('xlink:href')
    let name = option.getAttribute('data-name')
    let money = option.getAttribute('data-money')
    $(".transfer_" + type + "_wallet_now").find(".fake-input-icon").children("use").attr("xlink:href", src)
    $(".transfer_" + type + "_wallet_now").find(".info").children("span").eq(0).text(name)
    $(".transfer_" + type + "_wallet_now").find(".info").children("span").eq(1).text(money)
    $("#order_" + type).val(platform)
    this.closeManualTransferPop()
  }
}

