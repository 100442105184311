import consumer from "./consumer"

consumer.subscriptions.create('TransferChannel', {
  connected() {},

  disconnected() {},

  received(data) {
    let balanceSpans = document.querySelectorAll(`.balance-${data.service_name}`);
    let currencyBalanceSpans = document.querySelectorAll(`.${data.currency}-balance-${data.service_name}`);
    Array.from(balanceSpans).forEach(span => {
      let balance = isNaN(data.balance * 1) ? data.balance : data.balance * 1
      span.textContent = balance.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
    });
    Array.from(currencyBalanceSpans).forEach(span => {
      let balance = isNaN(data.balance * 1) ? data.balance : data.balance * 1
      span.textContent = balance.toLocaleString('zh-CN', { minimumFractionDigits: 2 });
    });
  }
});
