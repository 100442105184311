import { Controller } from 'stimulus'
import $ from 'jquery'
import Swiper, {Autoplay} from "swiper"
import Rails from "@rails/ujs"

let bonus = 1312121212
let bonusTimer = false

export default class extends Controller {
  static targets = [ 'form', 'result', 'nextPage', 'heroList' ]
  static values = { brandName: String }

  connect() {
    $( document ).ready(function() {
      $('.filter-room.is-acted').removeClass('is-scroll-to-acted')
    });
    if (!bonusTimer) {
      bonusTimer = true
      setInterval(this.jackpot, 1000)
    }
    this.winnerRandom()

    $(".filter-room").click(function () {
      $(this).parents(".filter-rooms").children('a').children(".filter-room").removeClass("is-acted");
      $(this).addClass("is-acted")
    })
    $(".item, .search-btn").click(function () {
      $(".no-content").hide()
      $(".loading").toggle()
      $(this).parent(".list").children('.item').removeClass("is-acted");
      $(this).addClass("is-acted")
    })
    $("#next-page").click(function () {
      $(".loading").toggle()
    })

    Swiper.use([Autoplay])

    if (this.brandNameValue == 'f6') {
        var swiper = new Swiper('.page-slots-detail .swiper-container', {
          direction: 'vertical',
          loop: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          slidesPerView: 1,
          breakpoints: {
            900: {
              slidesPerView: 2
            },
            1050: {
              slidesPerView: 3
            },
            1250: {
              slidesPerView: 4
            },
            1350: {
              slidesPerView: 5
            }
          }
        })
    } else {
        var swiper = new Swiper('.page-slots-detail .swiper-container', {
          direction: 'vertical',
          loop: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          slidesPerView: 6
        })
    }
  }

  search(e) {
    let target = e.target
    let nodeName = target.nodeName
    if (nodeName === 'LI' || nodeName === 'SPAN') {
      let filter = (nodeName === 'SPAN' ? target.parentNode : target)
      $('#' + filter.getAttribute('data-filter-type')).val(filter.getAttribute('value'))
    } else {
      $('#name').val($('#game_name').val())
    }
    Rails.fire(this.formTarget, 'submit')
  }

  result(event) {
    const response = event.detail[2]
    let data = JSON.parse(response.response)

    if (data.query.append) {
      this.resultTarget.innerHTML += data.html
    } else {
      this.resultTarget.innerHTML = data.html
    }

    if (data.query.page) {
      let url = new URL(response.responseURL)
      url.search = $.param(data.query)
      $(this.nextPageTarget).show().attr('href', url.toString())
    } else {
      $(this.nextPageTarget).hide()
    }
    $(".loading").toggle()
  }

  jackpot() {
    bonus += 25
    $('.bonus').html(Number(bonus).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
  }

  winnerRandom() {
    const usernamePrefix = this.brandNameValue == 'h6' ? 'vg' : 'ba'
    const userAccountWords = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    const slotsGame = {
      '01': 'AG 象棋老虎机',
      '02': 'AG 水果拉霸2',
      '03': 'YoPlay 奔驰宝马',
      '04': 'BG 赏金猎人',
      '05': 'MG 喝彩的旺财',
      '06': 'AG 糖果碰碰乐'
    }
    let heroCounts = this.heroListTarget.getAttribute('data-hero-counts')
    for (let i = 0; i < heroCounts ; i++) {
      let listItem = $(this.heroListTarget).children('div:eq(' + i + ')')
      const r1 = parseInt(Math.random() * 61)
      const r2 = parseInt(Math.random() * 61)
      const r3 = parseInt(Math.random() * 7500)
      const r4 = parseInt(Math.random() * 500)
      let fileName
      if (this.brandNameValue == 'h6') {
        fileName = this.fileName($('.game-img:eq(' + i + ')').css('background-image'))
      } else if (this.brandNameValue == 'f6') {
        fileName = this.fileName($('.game-img:eq(' + i + ')').attr('src'))
      }
      let heroInfo = listItem.children('div:eq(-2)')
      let heroBet = listItem.children('div:eq(-1)')
      heroInfo.children('.user_name').html(usernamePrefix + userAccountWords[r1] + '****' + userAccountWords[r2])
      heroInfo.children('.game_name').html(slotsGame[fileName])
      heroBet.children('.bet-count').append(' ' + r4)
      heroBet.children('.bonus-amount').append(' ' + r3.toLocaleString())
    }
  }

  fileName(imgPath) {
    return imgPath.split('/').pop().split('-').shift()
  }
}
