import $ from 'jquery'
import select2 from 'select2/dist/js/select2'
import 'select2/dist/js/i18n/en'
import 'select2/dist/js/i18n/zh-CN'
import 'select2/dist/css/select2.css'

document.addEventListener('turbolinks:load', () => {
    $('.select2').select2({}).on('select2:select', function () {
        let event = new Event('change', {bubbles: true})
        this.dispatchEvent(event);
    });
})
