import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    connect() {
        $('.filter-item').click(function() {
            let show = $(this).data("show");
            $(".filter-item").removeClass("is-acted");
            $(this).addClass("is-acted");
            $(".history-list").children(".history").hide();
            if(show === "all") {
                $(".history-list").children(".history").show();
            } else {
                $(".history-list").children(".show-"+show).show();
            }
        });
    }
}