import {Controller} from 'stimulus'
import consumer from "../channels/consumer";

export default class extends Controller {
    static targets = ['unread']

    connect() {
        this.channel = consumer.subscriptions.create('MessageChannel', {
            connected: this._cableConnected.bind(this),
            disconnected: this._cableDisconnected.bind(this),
            received: this._cableReceived.bind(this),
        })
    }

    _cableConnected() {
        // Called when the subscription is ready for use on the server
    }

    _cableDisconnected() {
        // Called when the subscription has been terminated by the server
    }

    _cableReceived(data) {
        if(data.count == 0) {
            this.unreadTarget.classList.remove("badge");
            this.unreadTarget.classList.add("hidden");
            this.unreadTarget.innerText = '';
        } else {
            this.unreadTarget.classList.remove("hidden");
            this.unreadTarget.classList.add("badge");
            this.unreadTarget.innerText = data.count;
        }
    }
}
