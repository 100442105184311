import { Controller } from "stimulus"
import Swiper, {Pagination, Autoplay, Lazy} from "swiper"

export default class extends Controller {
  connect(){
    Swiper.use([Pagination, Autoplay, Lazy])

    var swiper = new Swiper('.page-vip .swiper-container', {
      slidesPerView: 'auto',
      // spaceBetween: -100,
      centeredSlides: true,
      // autoHeight: true,
      loop: true,
      lazy: true,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      }
    });
  }
}