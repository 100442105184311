import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = ['content', 'ipSpan', 'toggleButton']
    static values = {
        allIps: String,
        initialIps: String,
        showTranslation: String,
        hideTranslation: String
    }
    showingAll = false

    showContent() {
        $(this.contentTarget).toggle()
    }

    showIp() {
        let toggleButton = document.getElementById('toggleButton');
        const allIps = this.allIpsValue
        const initialIps = this.initialIpsValue

        if (this.showingAll) {
            this.ipSpanTarget.textContent = initialIps + "..."
            toggleButton.innerText = this.showTranslationValue
            this.showingAll = false
        } else {
            this.ipSpanTarget.textContent = allIps
            toggleButton.innerText = this.hideTranslationValue
            this.showingAll = true
        }
    }
}