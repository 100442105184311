import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'password', 'icon' ]

  toggle() {
    let field = this.passwordTarget
    let icon = this.iconTarget

    if (field.type === 'password') {
      field.type = 'text'
    } else {
      field.type = 'password'
    }

    $(icon).toggleClass("fa-eye fa-eye-slash")
  }
}