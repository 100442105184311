import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static values = {
    path: String,
    language: String
  }

  connect() {
    $(document).on('turbolinks:load', () => {
      this.enableSelect2()
    })
    this.enableSelect2()
  }

  enableSelect2() {
    let path = this.pathValue
    let language = this.languageValue

    $('b[role="presentation"]').hide()
    
    $('.select2-container').remove()

    $('.select2').prop('disabled', false)

    $('#identifiers').select2({
      multiple: true,
      tags: true,
      tokenSeparators: [',', ' '],
      language: language,
      width: '100%'
    })

    $('#usernames').select2({
      ajax: {
        delay: 700,
        data: function(params) {
          return {
            user: { username: params.term },
            page: params.page || 1
          }
        },
        transport: function(params, success, _failure) {
          Rails.ajax({
            url: path,
            type: 'GET',
            dataType: 'json',
            data: decodeURIComponent($.param(params.data)),
            success: data => success(data)
          })
        },
        processResults: function(data, params) {
          params.page = params.page || 1
          return {
            results: $.map(data.user, function(user_data) { return { id: user_data.username, text: user_data.username } }),
            pagination: {
              more: (params.page * 50) < data.count
            }
          }
        }
      },
      cache: true,
      multiple: true,
      tags: true,
      tokenSeparators: [',', ' '],
      createTag: function(params) {
        return {
          id: params.term,
          text: params.term,
          newTag: true
        }
      },
      language: language,
      width: '100%'
    })
  }
}
