import { Controller } from "stimulus"
import '../scripts/jquery.marquee.min.js'

export default class extends Controller {
  static targets = ["marquee"]
  
  connect(){
    $(this.marqueeTargets).marquee({
      duration: 3000,
      gap: 50,
      delayBeforeStart: 1000,
      direction: "down"
    });
  }
}