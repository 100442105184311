import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static values = {
    type : String,
    title: String,
    description: String
  }

  openMemberPop() {
    $(".member-center-pop").find(".pop-type-image").hide()
    if(this.typeValue === "warning") {
      $(".member-center-pop").find(".pop-type-image.warning").show()
    } else {
      $(".member-center-pop").find(".pop-type-image.normal").show()
    }
    $(".member-center-pop").addClass("is-show");
    $(".member-center-pop").find(".title").text(this.titleValue)
    $(".member-center-pop").find("p").text(this.descriptionValue)
  }

  closeMemberPop() {
    $(".member-center-pop").removeClass("is-show");
  }
}