import { Controller } from 'stimulus'

export default class extends Controller {

  toggle(e) {
    let active_currency = e.target.value

    document.querySelectorAll(`.is-acted`).forEach( div => 
      { div.classList.add('hidden');
        div.classList.remove('is-acted');
      }
    )
    document.querySelectorAll(`.${active_currency}`).forEach( div => 
      { div.classList.remove('hidden');
        div.classList.add('is-acted');
      }
    )
  }
}