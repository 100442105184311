import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'username', 'currentSignInIp', 'fingerPrint' ]
  static values = {
    path: String,
    language: String
  }

  connect() {
    $(document).on('turbolinks:load', () => {
      if (this.hasFingerPrintTarget) {
        $('#fingerPrint').select2({}).on('select2:select', function () {
          let event = new Event('change', {bubbles: true})
          this.dispatchEvent(event)
        })
        this.enableFingerPrintSelect2()
      } else if (this.hasCurrentSignInIpTarget) {
        $('#currentSignInIp').select2({}).on('select2:select', function () {
          let event = new Event('change', {bubbles: true})
          this.dispatchEvent(event)
        })
        this.enableCurrentSignInIpSelect2()
      } else if (this.hasUsernameTarget) {
        $('#username').select2({}).on('select2:select', function () {
          let event = new Event('change', {bubbles: true})
          this.dispatchEvent(event)
        })
        this.enableUsernameSelect2()
      }
    })
    if (this.hasFingerPrintTarget) {
      this.enableFingerPrintSelect2()
    } else if (this.hasCurrentSignInIpTarget) {
      this.enableCurrentSignInIpSelect2()
    } else if (this.hasUsernameTarget) {
      this.enableUsernameSelect2()
    }
  }

  enableFingerPrintSelect2() {
    let path = this.pathValue
    let language = this.languageValue

    $('#fingerPrint ~ .select2-container').remove()

    $('#fingerPrint').select2({
      ajax: {
        delay: 700,
        data: (params) => ({
          query: 'finger_print',
          finger_print: params.term,
          page: params.page || 1
        }),
        transport: (params, success, _failure) => {
          Rails.ajax({
            url: path,
            type: 'GET',
            dataType: 'json',
            data: decodeURIComponent($.param(params.data)),
            success: data => success(data)
          })
        },
        processResults: (data, params) => {
          params.page = params.page || 1
          return {
            results: $.map(data.finger_prints, (fingerPrint) => ({ id: fingerPrint.finger_print, text: fingerPrint.finger_print })),
            pagination: {
              more: (params.page * 50) < data.count
            }
          }
        }
      },
      cache: true,
      multiple: true,
      tags: true,
      language: language,
      width: '100%'
    })
  }

  enableCurrentSignInIpSelect2() {
    let path = this.pathValue
    let language = this.languageValue

    $('#currentSignInIp ~ .select2-container').remove()

    $('#currentSignInIp').select2({
      ajax: {
        delay: 700,
        data: (params) => {
          return {
            query: 'current_sign_in_ip',
            current_sign_in_ip: params.term,
            page: params.page || 1
          }
        },
        transport: (params, success, _failure) => {
          Rails.ajax({
            url: path,
            type: 'GET',
            dataType: 'json',
            data: decodeURIComponent($.param(params.data)),
            success: data => {
              success(data)
            }
          })
        },
        processResults: (data, params) => {
          params.page = params.page || 1
          return {
            results: $.map(data.current_sign_in_ips, (ip) => ({ id: ip.current_sign_in_ip, text: ip.current_sign_in_ip })),
            pagination: {
              more: (params.page * 50) < data.count
            }
          }
        }
      },
      cache: true,
      multiple: true,
      tags: true,
      language: language,
      width: '100%'
    })
  }

  enableUsernameSelect2() {
    let path = this.pathValue
    let language = this.languageValue

    $('#username ~ .select2-container').remove()

    $('#username').select2({
      ajax: {
        delay: 700,
        data: (params) => {
          return {
            query: 'username',
            username: params.term,
            page: params.page || 1
          }
        },
        transport: (params, success, _failure) => {
          Rails.ajax({
            url: path,
            type: 'GET',
            dataType: 'json',
            data: decodeURIComponent($.param(params.data)),
            success: data => {
              success(data)
            }
          })
        },
        processResults: (data, params) => {
          params.page = params.page || 1
          return {
            results: $.map(data.usernames, (username) => ({ id: username.username, text: username.username })),
            pagination: {
              more: (params.page * 50) < data.count
            }
          }
        }
      },
      cache: true,
      multiple: true,
      tags: true,
      language: language,
      width: '100%'
    })
  }
}