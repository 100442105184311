import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    connect() {
        $(document).ready(function() {
            if ($(".deposit-method-sub-filter").length > 0) {
                var filter_main = $('.deposit-method-sub-filter')
                var filter_main_child = $('.deposit-method-sub-filter .filter-inner')
                var filter_child = ".deposit-method-sub-filter"
            } else if ($(".page_filter").length > 0) {
                var filter_main = $('.page_filter')
                var filter_main_child = $('.page_filter .filter_list_inner')
                var filter_child = ".page_filter"
            } else if ($(".filter-rooms").length > 0) {
                var filter_main = $('.filter-rooms')
                var filter_main_child = $('.filter-rooms .filter-inner')
                var filter_child = ".filter-rooms"
            } else if ($(".games-other-games").length > 0) {
                var filter_main = $('.games-other-games')
                var filter_main_child = $('.games-other-games .games-inner')
                var filter_child = ".games-other-games"
            }
            if (filter_main_child.width() < (filter_main_child[0].scrollWidth - 25)) {
                filter_main.addClass("is-show-more-icon")
                filter_main_child.on('scroll', function(e) {
                    if (($(this).width() + $(this).scrollLeft()) >= $(this)[0].scrollWidth - 25) {
                        $(this).parents(filter_child).removeClass("is-show-more-icon")
                    } else {
                        $(this).parents(filter_child).addClass("is-show-more-icon")
                    }
                })
            }
        })
    }
}