import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['adminProfile']

  dropdown(){
    $(this.adminProfileTarget).toggleClass("hidden")
  }
}
