import { Controller } from 'stimulus'
import $ from 'jquery'
import jstree from 'jstree'
import 'jstree/dist/themes/default/style.min.css'

const moved_nodes = {}

export default class extends Controller {
  static targets = ['tree']

  connect() {
    $(this.treeTarget).jstree({
      core: {
        check_callback: true
      },
      dnd: {
        is_draggable: function(node) {
          return node[0].type === 'enabled';
        }
      },
      types: {
        enabled: {
          icon: "fas fa-user"
        },
        disabled: {
          icon: "far fa-user"
        }
      },
      contextmenu: {
        items: function (node) {
          let tree = this.element.jstree(true);
          return {
            cut: {
              label: '剪下',
              action: function (obj) {
                tree.cut(node);
              },
              icon: 'fas fa-cut'
            },
            paste: {
              label: '贴上',
              action: function (obj) {
                tree.paste(node);
              },
              icon: 'fas fa-paste'
            }
          }
        }
      },
      plugins: ['dnd', 'types', 'contextmenu']
    }).on('move_node.jstree', function (e, data) {
      moved_nodes[data.node.id] = {
        username: data.node.text,
        ancestor: data.node.parent,
        generations: data.node.parents.length - 1 // root level = 0
      }
    })
  }

  updateTree() {
    let form = this.element
    form.tree.value = JSON.stringify(moved_nodes)
    form.submit()
  }
}
