document.addEventListener('turbolinks:load', () => {
  if (document.querySelector('.unsettled_amount')) {
    function getItems() {
      var items = document.querySelectorAll('.unsettled_amount');
      var itemCount = items.length;
      var total = 0;
      for(var i = 0; i < itemCount; i++) {
        total = total + parseInt(items[i].textContent);
      }
      document.getElementById('total_unsettled_amount').textContent = total.toFixed(2);
    }
    getItems();
  }
});
