import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tbody', 'depositTotal', 'withdrawTotal', 'depositWithdrawDiff', 'bettingCount',
                    'bettingAmount', 'bettingAmountValid', 'bettingProfit', 'transferPointAdd',
                    'transferPointRemove', 'bonusTotal', 'netProfit', 'settlementBetRatio', 'bonusBetRatio',
                    'betDepositRatio', 'bettingAverage', 'depositCount', 'depositAverage', 'withdrawCount',
                    'withdrawAverage', 'violation']
  static values = { usdtRate: Number }

  connect(){
    const orderAmountSubtotals = ['depositTotal', 'withdrawTotal', 'depositWithdrawDiff',
                                  'transferPointAdd', 'transferPointRemove', 'bonusTotal', 'violation'];
    const betsAmountSubtotals = ['bettingAmount', 'bettingAmountValid', 'bettingProfit'];
    const countSubtotals = ['bettingCount', 'depositCount', 'withdrawCount'];
    for (let subtotal of orderAmountSubtotals) this.orderAmountSubtotals(subtotal);
    for (let subtotal of betsAmountSubtotals) this.betsAmountSubtotals(subtotal);
    for (let subtotal of countSubtotals) this.countSubtotals(subtotal);
    this.rationSubtotals();
  }

  orderAmountSubtotals(columns) {
    const totals = document.querySelectorAll(`.${columns}`);
    let cny_result = 0.0;
    let usdt_result = 0.0;
    for(let total of totals) {
      let data = total.textContent.trim().split(/\s/).filter(e => e.length >= 1);
      if (data.length === 0) continue;
      let currency = data[0];
      let amount = data[1].replaceAll(',', '');
      if (currency == '¥') {
        cny_result = cny_result + parseFloat(amount);
      } else if (currency == '$') {
        usdt_result = usdt_result + parseFloat(amount);
      }
    }
    let target = this.targets.find(columns);
    target.innerHTML = `¥ ${cny_result.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</br>
                        $ ${usdt_result.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}`;
  }

  betsAmountSubtotals(columns) {
    const totals = document.querySelectorAll(`.${columns}`);
    let result = 0.0;
    for(let total of totals) {
      let data = total.textContent.trim().split(/\s/).filter(e => e.length >= 1);
      if (data.length === 0) continue;
      let currency = data[0];
      let amount = data[1].replaceAll(',', '');
      result = result + parseFloat(amount);
    }
    let target = this.targets.find(columns);
    target.innerHTML = `¥ ${result.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}`;
  }

  countSubtotals(columns) {
    const totals = document.querySelectorAll(`.${columns}`);
    let result = 0;
    for(let total of totals) {
      let count = total.textContent.trim().replaceAll(',', '');
      if (count == '') continue;
      result = result + parseInt(count);
    }
    let target = this.targets.find(columns);
    target.textContent = result;
  }

  rationSubtotals() {
    // settlementBetRatio
    let bettingProfit = this.bettingProfitTarget.textContent.split(/\s/).slice(-1)[0].replaceAll(',', '');
    let bettingResult = -parseFloat(bettingProfit);
    let bettingAmountValid = this.bettingAmountValidTarget.textContent.split(/\s/).slice(-1)[0].replaceAll(',', '');
    bettingAmountValid = parseFloat(bettingAmountValid);
    let settlementBetRatio = bettingAmountValid === 0 ? 0 : (bettingProfit / bettingAmountValid)
    this.settlementBetRatioTarget.textContent = settlementBetRatio.toLocaleString('zh-CN', { style: 'percent', minimumFractionDigits: 2 });

    // bettingAverage
    let bettingCount = this.bettingCountTarget.textContent;
    bettingCount = parseFloat(bettingCount)
    let bettingAverage = bettingCount === 0 ? 0 : (bettingAmountValid / bettingCount)
    this.bettingAverageTarget.textContent = `¥ ${bettingAverage.toFixed(2)}`;

    // depositAverage
    let depositCount = this.depositCountTarget.textContent;
    depositCount = parseFloat(depositCount);
    let depositTotal = this.depositTotalTarget.textContent.split(/\s/);
    let cnyDepositTotal = parseFloat(depositTotal[1].replaceAll(',', ''));
    let usdtDepositTotal = parseFloat(depositTotal.slice(-1)[0].replaceAll(',', ''));
    depositTotal = (cnyDepositTotal + usdtDepositTotal * this.usdtRateValue);
    let depositAverage = depositTotal / depositCount;
    this.depositAverageTarget.textContent = `¥ ${depositAverage.toFixed(2)}`;

    // withdrawAverage
    let withdrawCount = this.withdrawCountTarget.textContent;
    withdrawCount = parseFloat(withdrawCount);
    let withdrawTotal = this.withdrawTotalTarget.textContent.split(/\s/);
    let cnyWithdrawTotal = parseFloat(withdrawTotal[1].replaceAll(',', ''));
    let usdtWithdrawTotal = parseFloat(withdrawTotal.slice(-1)[0].replaceAll(',', ''));
    let withdrawAverage = (cnyWithdrawTotal + usdtWithdrawTotal * this.usdtRateValue) / withdrawCount;
    this.withdrawAverageTarget.textContent = `¥ ${withdrawAverage.toFixed(2)}`;

    // netProfit
    bettingProfit = parseFloat(bettingProfit)
    let bonusTotal = this.bonusTotalTarget.textContent.split(/\s/);
    let cnyBonusTotal = parseFloat(bonusTotal[1].replaceAll(',', ''));
    let usdtBonusTotal = parseFloat(bonusTotal.slice(-1)[0].replaceAll(',', ''));
    bonusTotal = cnyBonusTotal + usdtBonusTotal * this.usdtRateValue;
    let violation = this.violationTarget.textContent.split(/\s/);
    let cnyViolation = parseFloat(violation[1].replaceAll(',', ''));
    let usdtViolation = parseFloat(violation.slice(-1)[0].replaceAll(',', ''));
    violation = cnyViolation + usdtViolation * this.usdtRateValue;
    let netProfit = bettingProfit + violation - bonusTotal;
    this.netProfitTarget.textContent = `¥ ${netProfit.toFixed(2)}`

    // bonusBetRatio
    let bonusBetRatio = bettingAmountValid === 0 ? 0 : (bonusTotal / bettingAmountValid)
    this.bonusBetRatioTarget.textContent = bonusBetRatio.toLocaleString('zh-CN', { style: 'percent', minimumFractionDigits: 2 });

    // betDepositRatio
    this.betDepositRatioTarget.textContent = (bettingAmountValid / depositTotal).toFixed(2);
  }
}
