import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['changed', 'original', 'revised']

  connect() {
    this.isAgent = this.element.dataset.isAgent === 'true';
    this.countSubtotals();
  }
  
  countSubtotals() {
    const orderRows = document.querySelectorAll(`.order-row`);
    let attributes = ['changed', 'original', 'revised'];
    if (this.isAgent) {
      let orders = {
        changed: { bonusCny: 0.0, bonusUsdt: 0.0, commissionCny: 0.0, commissionUsdt: 0.0 },
        original: { bonusCny: 0.0, bonusUsdt: 0.0, commissionCny: 0.0, commissionUsdt: 0.0 },
        revised: { bonusCny: 0.0, bonusUsdt: 0.0, commissionCny: 0.0, commissionUsdt: 0.0}
      };
      for (let orderRow of orderRows) {
        let genre = this.selectClassInnerText(orderRow, 'genre');
        let orderInnertexts = this.orderInnerTexts(orderRow, attributes);
        let currency = orderInnertexts['changed'][0];
        if (genre == '佣金' || genre == 'commission') {
          if (currency == '¥') {
            orders = this.addOrder(orders, orderInnertexts, 'commissionCny');
          } else if (currency == '$') {
            orders = this.addOrder(orders, orderInnertexts, 'commissionUsdt');
          }
        } else {
          if (currency == '¥') {
            orders = this.addOrder(orders, orderInnertexts, 'bonusCny');
          } else if (currency == '$') {
            orders = this.addOrder(orders, orderInnertexts, 'bonusUsdt');
          }
        }
      }
      for (var key in orders) {
        this.setAgentSubtotal(orders[key], key);
      }
    } else {
      let orders = {
        changed: { realCny: 0.0, realUsdt: 0.0 },
        original: { realCny: 0.0, realUsdt: 0.0 },
        revised: { realCny: 0.0, realUsdt: 0.0 }
      }
      for (let orderRow of orderRows) {
        let orderInnertexts = this.orderInnerTexts(orderRow, attributes);
        let currency = orderInnertexts['changed'][0];
        if (currency == '¥') {
          orders = this.addOrder(orders, orderInnertexts, 'realCny');
        } else if (currency == '$') {
          orders = this.addOrder(orders, orderInnertexts, 'realUsdt');
        }
      }
      for (var key in orders) {
        this.setClientSubtotal(orders[key], key);
      }
    }
  }

  selectClassInnerText(orderRow, attribute) {
    return orderRow.querySelector(`.order-${attribute}`).innerText;
  }

  addOrder(orders, orderInnertexts, attribute) {
    for (var key in orders) {
      orders[key][attribute] += parseFloat(orderInnertexts[key][1].replace(/,/g, ''))
    }
    return orders;
  }

  orderInnerTexts(orderRow, attributes) {
    let results = {changed: null, original: null, revised: null};
    for (let attribute of attributes) {
      results[attribute] = this.selectClassInnerText(orderRow, attribute).trim().split(/\s+/);
    }
    return results;
  }

  setAgentSubtotal(results, attribute) {
    let data = this.targets.find(attribute);
    if (data) {
      data.childNodes[0].textContent += results.bonusCny.toLocaleString('zh-CN', {minimumFractionDigits: 2});
      data.childNodes[1].textContent += results.bonusUsdt.toLocaleString('zh-CN', {minimumFractionDigits: 2});
      data.childNodes[2].textContent += results.commissionCny.toLocaleString('zh-CN', {minimumFractionDigits: 2});
      data.childNodes[3].textContent += results.commissionUsdt.toLocaleString('zh-CN', {minimumFractionDigits: 2});
    }
  }

  setClientSubtotal(results, attribute) {
    let data = this.targets.find(attribute);
    if (data) {
      data.innerHTML = `
      <span class="flex flex-col">
      <span>¥ ${results.realCny.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</span>
      <span>$ ${results.realUsdt.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</span>
      <span>
      `;
    }
  }
}
