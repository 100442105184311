import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tbody', 'depositTotal', 'withdrawTotal', 'transferFromAgent', 'transferToAgent', 'bettingCount',
                    'bettingAmount', 'bettingAmountValid', 'bettingProfit','bonusTotal', 'violation', 'netProfit']
  static values = { usdtRate: Number }

  connect(){
    const orderAmountSubtotals = ['depositTotal', 'withdrawTotal', 'transferFromAgent', 'transferToAgent', 'bonusTotal', 'violation'];
    const betsAmountSubtotals = ['bettingAmount', 'bettingAmountValid', 'bettingProfit'];
    const countSubtotals = ['bettingCount'];
    for (let subtotal of orderAmountSubtotals) this.orderAmountSubtotals(subtotal);
    for (let subtotal of betsAmountSubtotals) this.betsAmountSubtotals(subtotal);
    for (let subtotal of countSubtotals) this.countSubtotals(subtotal);
    this.rationSubtotals();
  }



  orderAmountSubtotals(columns) {
    const totals = document.querySelectorAll(`.${columns}`);
    let cny_result = 0.0;
    let usdt_result = 0.0;
    for(let total of totals) {
      let data = total.textContent.trim().split(/\s/).filter(e => e.length >= 1);
      if (data.length === 0) continue;
      let currency = data[0];
      let amount = data[1].replaceAll(',', '');
      if (currency == '¥') {
        cny_result = cny_result + parseFloat(amount);
      } else if (currency == '$') {
        usdt_result = usdt_result + parseFloat(amount);
      }
    }
    let target = this.targets.find(columns);
    target.innerHTML = `¥ ${cny_result.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}</br>
                        $ ${usdt_result.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}`;
  }

  betsAmountSubtotals(columns) {
    const totals = document.querySelectorAll(`.${columns}`);
    let result = 0.0;
    for(let total of totals) {
      let data = total.textContent.trim().split(/\s/).filter(e => e.length >= 1);
      if (data.length === 0) continue;
      let currency = data[0];
      let amount = data[1].replaceAll(',', '');
      result = result + parseFloat(amount);
    }
    let target = this.targets.find(columns);
    target.innerHTML = `¥ ${result.toLocaleString('zh-CN', { minimumFractionDigits: 2 })}`;
  }

  countSubtotals(columns) {
    const totals = document.querySelectorAll(`.${columns}`);
    let result = 0;
    for(let total of totals) {
      let count = total.textContent.trim().replaceAll(',', '');
      if (count == '') continue;
      result = result + parseInt(count);
    }
    let target = this.targets.find(columns);
    target.textContent = result;
  }

  rationSubtotals() {
    let bettingProfit = this.bettingProfitTarget.textContent.split(/\s/).slice(-1)[0].replaceAll(',', '');

    // netProfit
    bettingProfit = parseFloat(bettingProfit)
    let bonusTotal = this.bonusTotalTarget.textContent.split(/\s/);
    let cnyBonusTotal = parseFloat(bonusTotal[1].replaceAll(',', ''));
    let usdtBonusTotal = parseFloat(bonusTotal.slice(-1)[0].replaceAll(',', ''));
    bonusTotal = cnyBonusTotal + usdtBonusTotal * this.usdtRateValue;
    let violation = this.violationTarget.textContent.split(/\s/);
    let cnyViolation = parseFloat(violation[1].replaceAll(',', ''));
    let usdtViolation = parseFloat(violation.slice(-1)[0].replaceAll(',', ''));
    violation = cnyViolation + usdtViolation * this.usdtRateValue;
    let netProfit = bettingProfit + violation - bonusTotal;
    this.netProfitTarget.textContent = `¥ ${netProfit.toFixed(2)}`
  }
}
