import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = ['name', 'nameError', 'amount', 'amountError',
        'minAmount', 'maxAmount', 'amountBorder', 'nameBorder', 'form']
    static values = {
        isBankTransfer: Boolean,
        isFixedQuota: Boolean,
        redirectUrl: String,
        amountType: String
    }

    initialize() {
        this.nameRegex = /^[\u3000\u3400-\u4DBF\u4E00-\u9FFF]+\u00B7?[\u3000\u3400-\u4DBF\u4E00-\u9FFF]+$/
        this.positiveIntegerRegex = /^\d+$/
        this.twoDecimalPlaceAmountRegex = /^\d+(\.\d{1,2})?$/
    }

    verifyName() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            showNameError(!this.isValidName(), this.nameErrorTarget, this.nameBorderTarget)
        }, 800)
    }

    verifyTwoDecimalPlaceAmount() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            if (this.twoDecimalPlaceAmountRegex.test(this.amountTarget.value)) {
                showTwoDecimalPlaceError(false, this.amountErrorTarget, this.amountBorderTarget)
                showAmountError(!this.isValidAmount(), this.amountErrorTarget, this.amountBorderTarget, this.minAmountTarget.value, this.maxAmountTarget.value, this.amountTypeValue)
                return true
            } else {
                showTwoDecimalPlaceError(true, this.amountErrorTarget, this.amountBorderTarget)
                return false
            }
        }, 800)
    }

    verifyPositiveIntegerAmount() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            if (this.positiveIntegerRegex.test(this.amountTarget.value)) {
                showPositiveIntegerError(false, this.amountErrorTarget, this.amountBorderTarget)
                showAmountError(!this.isValidAmount(), this.amountErrorTarget, this.amountBorderTarget, this.minAmountTarget.value, this.maxAmountTarget.value, this.amountTypeValue)
                return true
            } else {
                showPositiveIntegerError(true, this.amountErrorTarget, this.amountBorderTarget)
                return false
            }
        }, 800)
    }

    verifyFixedAmount() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            let regexNumberOnly = new RegExp(/[^0-9\.]/)
            if (regexNumberOnly.test(this.amountTarget.value)) {
                showPositiveIntegerError(true, this.amountErrorTarget, this.amountBorderTarget)
            } else {
                showPositiveIntegerError(false, this.amountErrorTarget, this.amountBorderTarget)
                showFixedAmountError(!this.isValidFixedAmount(), this.amountErrorTarget, this.amountBorderTarget, this.minAmountTarget.value, this.maxAmountTarget.value)
            }
        }, 800)
    }

    verifyFields(event) {
        let isAmountValid = this.isFixedQuotaValue ? this.isValidFixedAmount() : this.isValidAmount()
        if (this.hasNameTarget) {
            if (!this.isValidName() && !isAmountValid) {
                event.preventDefault()
                window.scrollTo(0, 0)
                this.nameTarget.focus()
                showNameError(true, this.nameErrorTarget, this.nameBorderTarget)
                showAmountError(true, this.amountErrorTarget, this.amountBorderTarget, this.minAmountTarget.value, this.maxAmountTarget.value, this.amountTypeValue)
            } else if (!this.isValidName()) {
                event.preventDefault()
                window.scrollTo(0, 0)
                this.nameTarget.focus()
                showNameError(true, this.nameErrorTarget, this.nameBorderTarget)
            } else if (!isAmountValid) {
                event.preventDefault()
                window.scrollTo(0, 0)
                this.amountTarget.focus()
                showAmountError(true, this.amountErrorTarget, this.amountBorderTarget, this.minAmountTarget.value, this.maxAmountTarget.value, this.amountTypeValue)
            } else if (this.isBankTransferValue) {
                event.preventDefault()
                this.formTarget.submit()
                setTimeout(() => {
                    window.location.href = this.redirectUrlValue
                }, 5000)
            }
        } else {
            if (!isAmountValid) {
                event.preventDefault()
                window.scrollTo(0, 0)
                this.amountTarget.focus()
                showAmountError(true, this.amountErrorTarget, this.amountBorderTarget, this.minAmountTarget.value, this.maxAmountTarget.value, this.amountTypeValue)
            }
        }
    }

    isValidName() {
        return this.nameRegex.test(this.nameTarget.value)
    }

    isValidAmount() {
        let minAmount = parseFloat(this.minAmountTarget.value)
        let maxAmount = parseFloat(this.maxAmountTarget.value)
        if (this.amountTypeValue === 'verifyPositiveIntegerAmount') {
            return ((this.amountTarget.value >= minAmount && this.amountTarget.value <= maxAmount) && this.amountTarget.value > 0.0 && this.positiveIntegerRegex.test(this.amountTarget.value))
        } else if (this.amountTypeValue === 'verifyTwoDecimalPlaceAmount') {
            console.log(this.twoDecimalPlaceAmountRegex.test(this.amountTarget.value))
            return ((this.amountTarget.value >= minAmount && this.amountTarget.value <= maxAmount) && this.amountTarget.value > 0.0 && this.twoDecimalPlaceAmountRegex.test(this.amountTarget.value))
        }
    }

    isValidFixedAmount() {
        let amount = parseFloat(this.amountTarget.value)
        let minAmount = parseFloat(this.minAmountTarget.value)
        let maxAmount = parseFloat(this.maxAmountTarget.value)

        return ((amount >= minAmount && amount <= maxAmount) && amount > 0.0)
    }
}

function showNameError(isValid, target, border) {
    if (isValid) {
        target.style.display = 'block'
        target.innerText = '不能为空，只接受中文。'
        border.classList.add('is-error')
    } else {
        target.style.display = 'none'
        target.innerText = ''
        border.classList.remove('is-error')
    }
}

function showAmountError(isValid, target, border, minAmount, maxAmount, amountType) {
    if (isValid) {
        target.style.display = 'block'
        border.classList.add('is-error')
        if (amountType === 'verifyPositiveIntegerAmount') {
            var hint = '汇款金额为整数建议不为整千百！请输入6003，6006等金额'
        } else if (amountType === 'verifyTwoDecimalPlaceAmount') {
            var hint = '汇款金额为小数两位！不要使用.00，建议输入6003.88，6006.66等金额'
        }

        if (maxAmount <= 900000) {
            target.innerText = `必须大于 0，最小值为 ${minAmount}，最大值为 ${maxAmount}，${hint}。`
        } else {
            target.innerText = `必须大于 0，最小值为 ${minAmount}，最大值为 ∞，${hint}。`
        }
    } else {
        target.style.display = 'none'
        target.innerText = ''
        border.classList.remove('is-error')
    }
}

function showFixedAmountError(isValid, target, border, minAmount, maxAmount) {
    if (isValid) {
        target.style.display = 'block'
        border.classList.add('is-error')
        target.innerText =
            `必须大于 0，最小值为 ${minAmount}，最大值为 ${maxAmount}。
            请选择选项内的金额。`
    } else {
        target.style.display = 'none'
        target.innerText = ''
        border.classList.remove('is-error')
    }
}

function showPositiveIntegerError(isValid, target, border) {
    if (isValid) {
        target.style.display = 'block'
        target.innerText = '须为正整数'
        border.classList.add('is-error')
    } else {
        target.style.display = 'none'
        target.innerText = ''
        border.classList.remove('is-error')
    }
}

function showTwoDecimalPlaceError(isValid, target, border) {
    if (isValid) {
        target.style.display = 'block'
        target.innerText = '须为小数点两位金额'
        border.classList.add('is-error')
    } else {
        target.style.display = 'none'
        target.innerText = ''
        border.classList.remove('is-error')
    }
}
