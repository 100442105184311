import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'amount', 'amountError', 'amountBorder', 'name', 'nameError', 'nameBorder']
  static values = {
    minAmount: Number,
    maxAmount: Number
  }

  initialize() {
    this.nameRegex = /^[\u3000\u3400-\u4DBF\u4E00-\u9FFF]+\u00B7?[\u3000\u3400-\u4DBF\u4E00-\u9FFF]+$/
  }

  verifyFields(event) {
    if (!this.isAmountValid()) {
      event.preventDefault()
      window.scrollTo(0, 0)
      this.amountTarget.focus()
      showAmountError(false, this.amountErrorTarget, this.amountBorderTarget)
    } else if (!this.isNameValid()) {
      event.preventDefault()
      window.scrollTo(0, 0)
      this.nameTarget.focus()
      showNameError(false, this.nameErrorTarget, this.nameBorderTarget)
    }
  }

  verifyAmount() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      showAmountError(this.isAmountValid(), this.amountErrorTarget, this.amountBorderTarget, this.minAmountValue, this.maxAmountValue)
    }, 800)
  }

  verifyName() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      showNameError(this.isNameValid(), this.nameErrorTarget, this.nameBorderTarget)
    }, 800)
  }

  isAmountValid() {
    let amount = parseFloat(this.amountTarget.value)
    let minAmount = parseFloat(this.minAmountValue)
    let maxAmount = parseFloat(this.maxAmountValue)

    return (amount >= minAmount && amount <= maxAmount) && amount > 0.0
  }

  isNameValid() {
    return this.nameRegex.test(this.nameTarget.value)
  }
}

function showAmountError(isAmountValid, target, border, minAmount, maxAmount) {
  if (isAmountValid) {
    target.style.display = 'none'
    target.innerText = ''
    border.classList.remove('border-red-500')
  } else {
    target.style.display = 'block'
    border.classList.add('border-red-500')
    if (maxAmount <= 900000) {
      target.innerText =
      `必须大于 0，最小值为 ${minAmount}，最大值为 ${maxAmount}。`
    } else {
        target.innerText =
        `必须大于 0，最小值为 ${minAmount}，最大值为 ∞。`
    }
  }
}

function showNameError(isNameValid, target, border) {
  if (isNameValid) {
    target.style.display = 'none'
    target.innerText = ''
    border.classList.remove('border-red-500')
  } else {
    target.style.display = 'block'
    target.innerText = '「不能为空，只接受中文。」'
    border.classList.add('border-red-500')
  }
}
