import { Controller } from 'stimulus'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = [ 'fingerPrint' ]
  static values = {
    token: String
  }

  connect() {
    if (!Cookies.get('finger_print')) {
      (async () => {
        const fpPromise = FingerprintJS.load({
          apiKey: this.tokenValue
        });

        fpPromise
            .then(fp => fp.get())
            .then(result => {
              this.fingerPrintTarget.value = result.visitorId;
              Cookies.set('finger_print', result.visitorId, { expires: 3 })
            });
      })();
    }
  }
}
