import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['smsBtn', 'codeInput']
  static values = {
    interval: String,
    btnHint: String,
    reachLimit: String
  }

  connect(){
    let countDownDate = new Date(this.intervalValue).getTime();
    if (this.reachLimitValue) this.disableSmsService();
    if (countDownDate) this.setCountDown(countDownDate, this.smsBtnTarget, this.btnHintValue);
  }

  disableSmsService(errorMsg) {
    this.smsBtnTarget.disabled = true;
    this.smsBtnTarget.style.cursor = 'not-allowed';
    this.smsBtnTarget.textContent = this.reachLimitValue || errorMsg;
  }

  sendSms(e) {
    e.preventDefault();
    if (this.smsBtnTarget.disabled) return;

    this.smsBtnTarget.disabled = true;
    Rails.ajax({
      type: 'POST', 
      url: this.smsBtnTarget.href,
      success: (response) => {
        if (response.limit) {
          this.disableSmsService(response.error);
          alert(`${response.message}、${response.error}`);
        } else {
          let countDownDate = new Date(response.interval).getTime();
          this.setCountDown(countDownDate);
          this.smsBtnTarget.disabled = false;
          alert(response.message);
        }
      },
      error: function(response) {
        alert(response);
        this.smsBtnTarget.disabled = false;
      }
    });
  }

  setCountDown(countDownDate, btnHint) {
    let countDown = setInterval(() => {
      let now = new Date().getTime();
      let distance = countDownDate - now;
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.smsBtnTarget.textContent = `请稍候 ${minutes}分 ${seconds}秒`;

      if (distance < 0) {
        clearInterval(countDown);
        this.smsBtnTarget.textContent = this.btnHintValue;
      }
    }, 1000);
  }
}
