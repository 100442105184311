import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = [ 'selectBankCode', 'methodLimitation', 'selectAccount' ]
  static values = {
    bankCode: String,
    paymentInfo: String
  }

  updateMethod() {
    let bankCodes = JSON.parse(this.bankCodeValue)
    let bankId = this.selectBankCodeTarget.value

    if (bankId) {
      let bankIndex = bankCodes.findIndex(v => v.id.toString() === bankId)
      this.methodLimitationTarget.textContent = bankCodes[bankIndex].minimum + '/' + bankCodes[bankIndex].maximum
    }
  }

  updatePayment() {
    let paymentInfo = JSON.parse(this.paymentInfoValue)
    let id = this.selectAccountTarget.value

    if (id) {
      let infoIndex = paymentInfo.findIndex(v => v.id.toString() === id)
      $('#order_bank_code').val(paymentInfo[infoIndex].from_bank_code)
      $('#order_name').val(paymentInfo[infoIndex].from_card_owner)
      $('#order_card_number').val(paymentInfo[infoIndex].from_card_number)
    }
  }
}

