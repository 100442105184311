import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static values = {
    path: String,
    user: Number,
    wallet: Number
  }

  connect() {
  }

  getBalances() {
    Rails.ajax({
      type: 'GET',
      url: this.pathValue,
      data: `user_id=${this.userValue}&wallet_id=${this.walletValue}`,
      dataType: 'script',
      success: (response) => {},
      error: (response) => {
        alert(response);
      }
    })
  }
}
