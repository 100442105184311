import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['columnGroup']

  addColumn() {
    this.toggleRemoveButton('block')

    let columnGroupDiv = this.columnGroupTarget.firstElementChild.cloneNode(true)
    columnGroupDiv.getElementsByTagName('input')[0].value = 0
    columnGroupDiv.getElementsByTagName('input')[0].min = 0
    this.columnGroupTarget.appendChild(columnGroupDiv)
  }

  removeColumn(e) {
    let column = (e.target.nodeName === 'svg' ? e.target.parentNode : e.target.parentNode.parentNode)
    column.remove()
    this.toggleRemoveButton('none')
  }

  toggleRemoveButton(display) {
    if (this.columnGroupTarget.childElementCount === 1) {
      this.columnGroupTarget.firstElementChild.getElementsByTagName('svg')[0].style.display = display
    }
  }
}
