import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'modal', 'activeLink', 'block', 'notice', 'csvLink', 'messageForm' ]
  static values = {
    url: String,
    id: Number,
    asyncSubmit: String,
    asyncPage: String,
    keepRemote: Boolean,
    dateLimit: Boolean
  }

  exportFile() {
    this.switchRequestFormat(true, 'js')
    let form = this.csvLinkTarget.form

    if (this.idValue) {
      if (this.dateLimitValue) {
        let msg = '时间区间只能小于30天(一个月)'
        let result = this.dateDifference(
          form.datetime_filter_start_at.value,
          form.datetime_filter_end_at.value
        )
        if (result) return(alert(msg))
      }
      if (!form || form.checkValidity()) {
        this.csvLinkTarget.click();
        this.uploadNotify();
      } else {
        alert('请填写必填栏位');
        this.csvLinkTarget.click();
      }
    } else {
      this.getTelegramId();
    }

    this.switchRequestFormat(false, 'html')
  }

  getTelegramId() {
    Rails.ajax({
      type: 'GET', 
      url: this.urlValue,
      success: (response) => {
        if (response.telegram_id) {
          this.csvLinkTarget.click();
          this.uploadNotify();
        } else {
          this.showModal();
        }
      },
      error: function(response) {
        alert(response);
      }
    });
  }

  showModal(e) {
    this.modalTarget.classList.remove('hidden');
    if (e) {
      this.messageFormTarget.elements.message.value = '';
      this.messageFormTarget.elements.message.focus();
      this.messageFormTarget.action = `/telegram/${e.target.dataset.telegramId}/send_system_message`;
    }
  }

  closeModal() {
    this.modalTarget.classList.add('hidden');
    location.reload();
  }

  bind() {
    this.activeLinkTarget.classList.remove('text-blue-600', 'font-bold');
    this.activeLinkTarget.classList.add('text-gray-500');
    this.blockTarget.classList.remove('hidden');
    this.blockTarget.classList.add('block');
    this.noticeTarget.classList.remove('hidden');
  }

  uploadNotify() {
    alert('请求成功，完成后会发送通知到您的 TG 帐号上');
  }

  sendMessage(e) {
    if (!this.messageFormTarget.elements.message.value) {
      e.preventDefault();
      alert('请输入讯息');
    } else {
      this.closeModal();
      setTimeout(function() {
        alert('讯息已发送');
      }, 500)
    }
  }

  switchRequestFormat(remote, format) {
    if (this.asyncSubmitValue && !this.keepRemoteValue) {
      this.csvLinkTarget.form.dataset.remote = remote
    }
    if (this.asyncPageValue === 'bets') {
      this.csvLinkTarget.form.search_format.value = format
    }
  }

  dateDifference(start, end) {
    const days = 30
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const startDate = new Date(start)
    const endDate = new Date(end)
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay))

    return(diffDays > days)
  }
}
